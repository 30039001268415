import React, { Component } from "react";
import Checkbox from "react-bootstrap/lib/Checkbox";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import ImageGallery from "react-image-gallery";
import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./product.css";
import ProductDataHandler from "./ProductDataHandler.js";
import EmailPopup from "../EmailPopup/EmailPopup.js";
import VideoPlayer from "../VideoPlayer/videoPlayer.js";
import NamePopup from "../NamePopup/NamePopup.js";
import Api from "../Utils/api";
import ToolsDataHandler from "../Tools/tools_data_handler";
import ProductConfigurator from "../ProductConfigurator/ProductConfigurator";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import ContentLoading from "../ContentLoading/ContentLoading.js";

require("react-image-gallery/styles/css/image-gallery.css");

const priceTierArray = {
  Tier1: 3,
  Preferred: 4,
  "Distributor Net": 5,
};

const branchBasedRoles = ["Distributor User", "Branch Admin"];

/**
 * Represents the product image and information shown when specific product is selected
 */
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      attachments: [],
      additionalAttachments: [],
      videoList: [],
      selectedFilesForShare: [],
      selectedVideosForShare: [],
      showVideo: false,
      videoLink: "",
      templateString: "",
      showPinNamePopup: false,
      applicationType: "",
      postId: "",
      valid: false,
      isPinned: false,
      resourceType: 0,
    };

    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handleProductDetails = this.handleProductDetails.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleAdditionalAttachments =
      this.handleAdditionalAttachments.bind(this);
    this.handleEmailShare = this.handleEmailShare.bind(this);
    this.handleCloseEmailPopup = this.handleCloseEmailPopup.bind(this);
    this.addLog = this.addLog.bind(this);
    this.handleCloseVideo = this.handleCloseVideo.bind(this);
    this.handlePinPage = this.handlePinPage.bind(this);
    this.pinProduct = this.pinProduct.bind(this);
    this.handleCloseNamePopup = this.handleCloseNamePopup.bind(this);
    this.handleDiscardPinPopup = this.handleDiscardPinPopup.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.setUserAccess = this.setUserAccess.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.postId !== nextProps.postId) {
      this.setState(
        {
          error: null,
          isLoaded: false,
          selectedFilesForShare: [],
          selectedVideosForShare: [],
          applicationType: nextProps.applicationType,
          postId: this.props.postId,
        },
        () => {
          this.setUserAccess(nextProps.postId);
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.location?.state) {
      let params = this.props.location.state;
      if (params.pinned) {
        this.setUserAccess(params.postId);
        this.setState({ isPinned: true });
      }
      if (params.redirection) {
        this.setUserAccess(params.postId);
      }
    }
    window.scrollTo(0, 0);
  }

  setUserAccess(params) {
    let role = sessionStorage.getItem("userRole");
    if (branchBasedRoles.includes(role)) {
      let username = sessionStorage.getItem("username");
      ToolsDataHandler.getPricingDetails(username)
        .then((response) => {
          if (response.success) {
            if (response.data.pricingAccess) {
              this.setState(
                {
                  resourceType: priceTierArray[response.data.priceTier],
                },
                () => {
                  this.getData(params);
                }
              );
            } else {
              this.setState(
                {
                  resourceType: 2,
                },
                () => {
                  this.getData(params);
                }
              );
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.addNotification(ErrorMessages.Product_ResourceType, "error");
        });
    } else {
      this.getData(params);
    }
  }

  render() {
    if (!this.state.valid && this.state.isLoaded) {
      return this.noProductsAvailableSection();
    } else if (this.state.error) {
      return this.emptyProductsSection();
    } else {
      return this.productListSection();
    }
  }

  noProductsAvailableSection() {
    return (
      <center>
        <h4>No Product To Display!</h4>
      </center>
    );
  }

  emptyProductsSection() {
    return (
      <center>
        <h4>Error: {this.state.error.message}</h4>
      </center>
    );
  }

  productLoadingSection() {
    return <ContentLoading message={"Loading..."} size={50} />;
  }

  productListSection() {
    return this.state.isLoaded
      ? this.productDisplaySection()
      : this.productLoadingSection();
  }

  productDisplaySection() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div className="loading-fade">
        {this.productInformationSection()}
        <ProductConfigurator
          postId={this.state.postId}
          title={this.state.productData.title}
        />
        <EmailPopup
          show={this.state.showEmailPopup}
          attachments={this.state.selectedFilesForShare}
          handleOnClose={this.handleCloseEmailPopup}
          addNotification={this.addNotification}
          emailFromProductPage={true}
          clearSelection={this.clearSelection}
          emailTemplate={this.state.templateString}
        />
        <VideoPlayer
          show={this.state.showVideo}
          handleOnClose={this.handleCloseVideo}
          URL={this.state.videoLink}
          title={this.state.videoTitle}
        />
        <NamePopup
          show={this.state.showPinNamePopup}
          title="Pin Page"
          handleOnClose={this.handleCloseNamePopup}
          handleOnDiscard={this.handleDiscardPinPopup}
          handleOnSubmit={this.pinProduct}
          addNotification={this.addNotification}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  getData(postId) {
    ProductDataHandler.getProductDetails(postId)
      .then((productDetails) => {
        this.handleProductDetails(productDetails);
      })
      .catch((error) => {
        this.handleError(error);
        this.addNotification(ErrorMessages.Product_ProductDetails, "error");
      });
    this.setState({ postId: postId, valid: true });
  }
  handleProductDetails(productDetails) {
    let attachmentList = [];
    for (const prop in productDetails.files) {
      productDetails.files[prop].forEach((item) => {
        attachmentList.push(item);
      });
    }
    const images = this.getProductImages(productDetails.images);
    const videos = productDetails.youtube_url ? productDetails.youtube_url : [];
    this.setState({
      isLoaded: true,
      productData: productDetails,
      attachments: attachmentList,
      imageList: images,
      videoList: videos,
    });

    ProductDataHandler.getAditionalAttachments(
      productDetails.title,
      this.state.resourceType.toString()
    )
      .then((additionalAttachments) => {
        this.handleAdditionalAttachments(additionalAttachments);
      })
      .catch((error) => {
        this.handleError(error);
        this.addNotification(
          ErrorMessages.Product_AdditionalAttachments,
          "error"
        );
      });

    ProductDataHandler.insertLog(productDetails.title, "View").catch(
      (error) => {
        this.handleError(error);
        this.addNotification(ErrorMessages.Product_InsertLog, "error");
      }
    );
  }
  handleAdditionalAttachments(additionalAttachments) {
    let uploads = additionalAttachments.data.list.map((item) => {
      item.filePath = Api.getBaseURL() + "/" + item.filePath;
      return item;
    });

    let installationImages = additionalAttachments.data.imageList.map(
      (item) => {
        let filePath = Api.getBaseURL() + "/" + item.filePath;
        return {
          original: filePath,
          thumbnail: filePath,
        };
      }
    );

    installationImages = this.state.imageList.concat(installationImages);
    this.setState({
      additionalAttachments: uploads,
      imageList: installationImages,
    });
  }
  handleError(error) {
    this.setState({
      isLoaded: true,
      error: error,
    });
  }

  productInformationSection() {
    return (
      <div>
        <Row className={this.state.isPinned ? "invisible-div" : "visible-div"}>
          <Button
            className="button-blue"
            bsStyle="primary"
            id="pin-button"
            onClick={this.handlePinPage}
          >
            <span className="glyphicon glyphicon-pushpin pin-icon" />
          </Button>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <div className="gallery-container">
              <ImageGallery
                items={this.state.imageList}
                showThumbnails={this.imageListHasMoreThanOneImage()}
                showPlayButton={this.imageListHasMoreThanOneImage()}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <h3 className="product-title">{this.state.productData.title}</h3>
            <div
              className="product-short-description"
              dangerouslySetInnerHTML={{
                __html: this.state.productData.short_description,
              }}
            />
            <hr />
            <div
              className="product-description"
              dangerouslySetInnerHTML={{
                __html: this.state.productData.description,
              }}
            />
            <table className="attachment-table">
              <tbody>
                {this.generateAttachmentList()}
                {this.generateAdditionalAttachmentList()}
                {this.generateVideoList()}
              </tbody>
            </table>
            <Row>
              <Col md={10} />
              <Col md={2} style={{ paddingRight: "0px" }}>
                <Button
                  bsStyle="primary"
                  bsSize="small"
                  style={{ float: "right" }}
                  className={this.showShareButton() + " button-blue"}
                  onClick={this.handleEmailShare}
                >
                  <span className="glyphicon glyphicon-share" />
                  &nbsp;Share
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
  getProductImages(imageList) {
    const images = [];
    imageList.forEach((item) => {
      images.push({
        original: item.src,
        thumbnail: item.src,
      });
    });
    return images;
  }
  generateAttachmentList() {
    return this.state.attachments.map((item, i) => {
      let fileName = item.slice(
        item.lastIndexOf("/") + 1,
        item.lastIndexOf(".")
      );
      return this.generateAttachmentItem(fileName, item, i, "remote", "-1");
    });
  }
  generateAdditionalAttachmentList() {
    return this.state.additionalAttachments.map((item, i) => {
      let fileName = item.original_file_name.slice(
        0,
        item.original_file_name.lastIndexOf(".")
      );
      let filePath = item.filePath;
      return this.generateAttachmentItem(
        fileName,
        filePath,
        i,
        "local",
        item.id
      );
    });
  }
  generateVideoList() {
    return this.state.videoList.map((item, i) => {
      let fileName = this.state.productData.title + "_Video-" + (i + 1);
      return this.generateVideoElement(fileName, item, i, "remote", "-1");
    });
  }
  handleFileSelect(filePath, fileName, source, id) {
    let fileIdList = this.state.selectedFilesForShare;
    if (fileIdList.find((item) => item.filePath === filePath)) {
      fileIdList = fileIdList.filter((item) => item.filePath !== filePath);

      this.trackRelevantDocumentSelection(fileName, id, false);
    } else {
      this.trackRelevantDocumentSelection(fileName, id, true);

      fileIdList.push({
        filePath: filePath,
        fileName: fileName,
        source: source,
        id: id,
      });
    }
    this.setState({
      selectedFilesForShare: fileIdList,
    });
  }
  handleVideoSelect(filePath, fileName) {
    let fileIdList = this.state.selectedVideosForShare;
    if (fileIdList.find((item) => item.filePath === filePath)) {
      fileIdList = fileIdList.filter((item) => item.filePath !== filePath);
    } else {
      fileIdList.push({ filePath: filePath, fileName: fileName });
    }
    this.setState({
      selectedVideosForShare: fileIdList,
    });
  }
  isChecked(filePath) {
    let fileIdList = this.state.selectedFilesForShare;
    let isFileIdChecked = false;
    if (fileIdList.find((item) => item.filePath === filePath)) {
      isFileIdChecked = true;
    }
    return isFileIdChecked;
  }
  isVideoChecked(filePath) {
    let fileIds = this.state.selectedVideosForShare;
    let isVideoChecked = false;
    if (fileIds.find((item) => item.filePath === filePath)) {
      isVideoChecked = true;
    }
    return isVideoChecked;
  }
  clearSelection() {
    this.setState({
      selectedFilesForShare: [],
      selectedVideosForShare: [],
    });
  }
  handleEmailShare() {
    if (
      this.state.selectedFilesForShare.length <= 0 &&
      this.state.selectedVideosForShare.length <= 0
    ) {
      this.addNotification(
        ErrorMessages.Product_NoFileSelectedForShare,
        "warning"
      );
    } else {
      let data = {
        PortalWebsite: Miscellaneous.PortalWebsite,
        PortalName: Miscellaneous.PortalName,
        videoUrls: this.state.selectedVideosForShare,
      };
      ToolsDataHandler.getEmailTemplate(data)
        .then((response) => {
          if (!response.success) {
            this.addNotification(response.message, "error");
          } else {
            this.setState({
              showEmailPopup: true,
              templateString: response.data.body,
            });
          }
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.Product_EmailTemplate, "error");
        });
    }

    this.trackEmailShareButtonClick(
      this.state.selectedFilesForShare.length <= 0 ? false : true
    );
  }
  handleCloseEmailPopup() {
    this.setState({
      showEmailPopup: false,
    });
  }
  handleCloseVideo() {
    this.setState({
      showVideo: false,
    });
  }
  generateAttachmentItem(fileName, filePath, key, source, id) {
    let extension = filePath.slice(
      filePath.lastIndexOf(".") + 1,
      filePath.length
    );
    let button;
    if (extension === "pdf" || extension === "PDF") {
      button = (
        <Button
          bsStyle="primary"
          bsSize="small"
          className="productdownload-btn button-blue"
          href={filePath}
          target="_blank"
          onClick={() => this.addLog(fileName, "Download")}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    } else {
      button = (
        <Button
          bsStyle="primary"
          bsSize="small"
          className="productdownload-btn button-blue"
          href={filePath}
          download={fileName + "." + extension}
          onClick={() => this.addLog(fileName, "Download")}
        >
          <span className="glyphicon glyphicon-save" />
          &nbsp;Download
        </Button>
      );
    }
    return (
      <tr className="attachment-table-row" key={key}>
        <td className="attachment-name-cell">
          <Checkbox
            onChange={() =>
              this.handleFileSelect(filePath, fileName, source, id)
            }
            checked={this.isChecked(filePath)}
          >
            {fileName}
          </Checkbox>
        </td>
        <td className="attachment-download-cell"> {button} </td>
      </tr>
    );
  }
  generateVideoElement(fileName, videoUrl, key, source, id) {
    let button = (
      <Button
        bsStyle="primary"
        bsSize="small"
        className="productdownload-btn button-blue"
        onClick={() => this.playVideo(videoUrl, fileName)}
      >
        <span className="glyphicon glyphicon-play" />
        &nbsp;&nbsp;Play
      </Button>
    );
    return (
      <tr className="attachment-table-row" key={key}>
        <td className="attachment-name-cell">
          <Checkbox
            onChange={() => this.handleVideoSelect(videoUrl, fileName)}
            checked={this.isVideoChecked(videoUrl)}
          >
            {fileName}
          </Checkbox>
        </td>
        <td className="attachment-download-cell"> {button} </td>
      </tr>
    );
  }

  addLog(fileName, action) {
    ProductDataHandler.insertLog(fileName, action)
      .then(() => {
        this.trackDownloadDocumentButtonClick(fileName);
      })
      .catch((error) => {
        this.handleError(error);
        this.addNotification(ErrorMessages.Product_InsertLog, "error");
      });
  }

  imageListHasMoreThanOneImage() {
    let multipleImagesInList = false;
    if (this.state.imageList && this.state.imageList.length > 1) {
      multipleImagesInList = true;
    }
    return multipleImagesInList;
  }

  showShareButton() {
    if (this.state.attachments.length <= 0) {
      return "hide-component";
    } else {
      return "show-component";
    }
  }

  playVideo(url, fileName) {
    this.setState({
      showVideo: true,
      videoLink: url.replace("watch?v=", "embed/") + "?autoplay=1",
      videoTitle: fileName,
    });
  }

  handlePinPage() {
    this.trackPinButtonClick();
    this.setState({
      showPinNamePopup: true,
    });
  }

  pinProduct(pinName) {
    let jsonData = {
      postId: this.props.postId,
    };
    const data = {
      title: pinName,
      redirectPage: "Product",
      parameters: jsonData,
    };

    ProductDataHandler.postPinPage(data)
      .then((response) => {
        if (response.success) {
          this.trackPinPageSubmitClick(pinName);
          this.addNotification(DisplayMessages.Product_PinnedMsg, "info");
        } else {
          this.trackPinPageFailedClick(pinName, response.message);
          this.addNotification(response.message, "warning");
        }
      })
      .catch((error) => {
        this.trackPinPageFailedClick(
          pinName,
          ErrorMessages.Product_PostPinPage
        );
        this.addNotification(ErrorMessages.Product_PostPinPage, "error");
      });
  }

  handleDiscardPinPopup() {
    this.trackPinPageDiscardClick();
    this.handleCloseNamePopup();
  }

  handleCloseNamePopup() {
    this.setState({
      showPinNamePopup: false,
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackPinButtonClick() {
    mixpanel.track("Pin Content", {
      Action: "Button Click",
      Effect: "Current product page will be pinned",
      Content: "Product Page",
    });
  }

  trackPinPageDiscardClick() {
    mixpanel.track("Discard Pin Content", {
      Action: "Button Click",
      Effect: "Current product page will not be pinned",
      Content: "Product Page",
    });
  }

  trackPinPageSubmitClick(pinName) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current product page is pinned",
      Content: "Product Page",
      "Pinned Name": pinName,
    });
  }

  trackPinPageFailedClick(pinName, message) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current product page is not pinned",
      Content: "Product Page",
      "Pinned Name": pinName,
      Message: message,
    });
  }

  trackRelevantDocumentSelection(fileName, id, selection) {
    mixpanel.track("Product Document Selection", {
      Action: "Checkbox Click",
      Effect: "Select/Deselect Specified document",
      Selected: selection,
      FileName: fileName,
      "Document ID": id,
    });
  }

  trackDownloadDocumentButtonClick(fileName) {
    mixpanel.track("Product Document Download", {
      Action: "Button Click",
      Effect: "Download selected document",
      FileName: fileName,
    });
  }

  trackEmailShareButtonClick(open) {
    mixpanel.track("Share Product Documents", {
      Action: "Button Click",
      Effect: open ? "Open email share popup" : "No documents selected",
    });
  }
}
export default Product;
