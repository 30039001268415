import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "react-bootstrap/lib/Button";
import Tooltip from "react-bootstrap/lib/Tooltip";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Select from "react-select";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import NotificationSystem from "react-notification-system";
import Panel from "react-bootstrap/lib/Panel";
import Spinner from "react-loading";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import mixpanel from "mixpanel-browser";

import "./orderSummary.css";
import NamePopup from "../NamePopup/NamePopup.js";
import OrderSummaryHandler from "./OrderSummaryHandler.js";
import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp.js";
import Disclaimer from "../Disclaimer/Disclaimer";
import DownloadPopup from "../DownloadPopup/DownloadPopup";
import OkPopUp from "../OkPopUp/OkPopUp.js";
import FormalQuotesPopup from "./FormalQuotesPopup";
import DistributorManagementHandler from "../DistributorManagement/DistributorManagementHandler";
import SubmitPOPopup from "./SubmitPOPopup";
import DownloadCartPopup from "./DownloadCartPopup";
import Creatable from "react-select/creatable";
import DeleteConfirmationPopup from "../ConfirmationPopUp/DeleteConfirmationPopup";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import fixedLink from "../Assets/fixedAssets.json";
import {
  DisplayMessages,
  ErrorMessages,
  fetchResourceData,
  Miscellaneous,
} from "../Utils/FetchConfigurableData";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import ContentLoading from "../ContentLoading/ContentLoading.js";
import { CopyToClipboard } from "../Utils/CopyToClipboard.js";
import DownloadProgress from "../DownloadPopup/DownloadProgress.js";

const blankImg = fetchResourceData(fixedLink.blankIcon);

let listOfRecords = [];
let totalprice = 0.0;
let preMarginTotal = 0.0;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:hover": { backgroundColor: "lightgray" },
    padding: 5,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid #ccc",
    borderRadius: "0px",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
    height: "20px",
    minHeight: "34px",
    fontSize: "14px",
    color: "#555",
    transition: "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
  }),
  placeholder: (base) => ({
    ...base,
    color: "gray",
  }),
  singleValue: (base) => ({
    ...base,
    color: "gray",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};

let stockingTypes = [
  { value: "New Buy", label: "New Buy" },
  { value: "Restock", label: "Restock" },
];

const customerBasedRoles = ["360 Admin", "Internal Salesrep", "Salesrep"];
const branchBasedRoles = ["Distributor User", "Branch Admin"];
const orderDetailsButton = ["RequestQuote", "SubmitPO", "DownloadCart"];
const orderTypeList = ["Stocking", "Project"];

const today = new Date().toLocaleDateString(Miscellaneous.LocaleDate);

/**
 * Component represents the estimate summary cart page
 */
class OrderSummary extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      orderSummaryData: [],
      showPinNamePopup: false,
      isFormalQuoteClicked: false,
      isSubmitPopupClicked: false,
      isDownloadCartClicked: false,
      projectOrStockingType: "",
      customerName: "",
      projectName: "",
      contractorName: "",
      isClearButtonClicked: false,
      allFieldFilled: false,
      body: "",
      branch: "",
      status: false,
      last_updated: "",
      responseReceived: false,
      isViewClicked: false,
      stockdetails: [],
      format: "",
      extension: "",
      name: "",
      date: "",
      selectedAddress: "",
      savedAddress: [],
      street: "",
      city: "",
      province: "",
      postalCode: "",
      selectedStockingType: "",
      desiredShipDate: "",
      selectedSpec: "No",
      currentRole: "",
      currentBranch: null,
      freeShipping: 0,
      showDelete: false,
      deleteType: "",
      deleteItem: "",
      title: "",
      pricingAccess: true,
      goldenPriceAccess: true,
      contractorList: [],
      asteriskFirstRun: true,
      totalWarehouseFee: 0,
      downloadLoader: false,
      GoldenLegend: false,
      buttonSelected: null,
      showExportNamePopup: false,
      quoteNumber: "",
      provinceList: [],
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      specValue: "default",
      engSpecLink: "",
      frSpecLink: "",
      itemCategory: "accessories",
    };
    this._notificationSystem = null;
    this.addNotification = this.addNotification.bind(this);
    this.handlePinPage = this.handlePinPage.bind(this);
    this.getFormalQuote = this.getFormalQuote.bind(this);
    this.SubmitPOButtonHandler = this.SubmitPOButtonHandler.bind(this);
    this.DownloadCartHandler = this.DownloadCartHandler.bind(this);
    this.handleDownloadCartClose = this.handleDownloadCartClose.bind(this);
    this.handleOnSubmitPOClose = this.handleOnSubmitPOClose.bind(this);
    this.handleOnRequestQuoteClose = this.handleOnRequestQuoteClose.bind(this);
    this.handleTypeSelection = this.handleTypeSelection.bind(this);
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
    this.downloadOrderSummary = this.downloadOrderSummary.bind(this);
    this.pinOrderSummary = this.pinOrderSummary.bind(this);
    this.handleCloseNamePopup = this.handleCloseNamePopup.bind(this);
    this.handleDiscardPinPopup = this.handleDiscardPinPopup.bind(this);
    this.handleClearClose = this.handleClearClose.bind(this);
    this.clearOrderSummaryTable = this.clearOrderSummaryTable.bind(this);
    this.clearTableData = this.clearTableData.bind(this);
    this.cartItemsTable = this.cartItemsTable.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.handleSubmitButtonDownload =
      this.handleSubmitButtonDownload.bind(this);
    this.getAllDetails = this.getAllDetails.bind(this);
    this.CalculateTotalPrice = this.CalculateTotalPrice.bind(this);
    this.onQtyChange = this.onQtyChange.bind(this);
    this.onBlurQtyChange = this.onBlurQtyChange.bind(this);
    this.viewButtonClicked = this.viewButtonClicked.bind(this);
    this.handleOnCloseMissing = this.handleOnCloseMissing.bind(this);
    this.updateMarginPercent = this.updateMarginPercent.bind(this);
    this.onBlurUpdateMargin = this.onBlurUpdateMargin.bind(this);
    this.modifyNotes = this.modifyNotes.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.assignPhotoId = this.assignPhotoId.bind(this);
    this.onMarginChange = this.onMarginChange.bind(this);
    this.onBlurMarginChange = this.onBlurMarginChange.bind(this);
    this.setAdditionalMargin = this.setAdditionalMargin.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateOrderSummary = this.updateOrderSummary.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleStreetChange = this.handleStreetChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.handleStockingTypeChange = this.handleStockingTypeChange.bind(this);
    this.handleSpecSelection = this.handleSpecSelection.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.getSavedAddress = this.getSavedAddress.bind(this);
    this.getButtonStatus = this.getButtonStatus.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.RequiredFieldsFilled = this.RequiredFieldsFilled.bind(this);
    this.handleContractorChange = this.handleContractorChange.bind(this);
    this.AdditionalNotesChange = this.AdditionalNotesChange.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.saveContractor = this.saveContractor.bind(this);
    this.getContractor = this.getContractor.bind(this);
    this.deleteContractor = this.deleteContractor.bind(this);
    this.handleDeleteContractor = this.handleDeleteContractor.bind(this);
    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
    this.getShippingOrderDetails = this.getShippingOrderDetails.bind(this);
    this.getShippingAddress = this.getShippingAddress.bind(this);
    this.getCartItems = this.getCartItems.bind(this);
    this.saveShippingOrderDetails = this.saveShippingOrderDetails.bind(this);
    this.asteriskUpdate = this.asteriskUpdate.bind(this);
    this.handleContractorInputChange =
      this.handleContractorInputChange.bind(this);
    this.onNextButtonClicked = this.onNextButtonClicked.bind(this);
    this.onShipDateChange = this.onShipDateChange.bind(this);
    this.handleQuoteNumberChange = this.handleQuoteNumberChange.bind(this);
    this.getProvinces = this.getProvinces.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.handleOnPopupClose = this.handleOnPopupClose.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
    this.getUserCart = this.getUserCart.bind(this);
    this.copyItem = this.copyItem.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;

    this.getCurrentUser();
    this.getSavedAddress();
    this.getContractor();
    this.getProvinces();
    this.getUserCart();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserCart() {
    OrderSummaryHandler.getOrderSummaryCart()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState(
            {
              orderSummaryData: response.data.list.map((item) => {
                if (JSON.parse(sessionStorage.getItem("goldenPriceAccess"))) {
                  return item;
                } else {
                  return { ...item, GoldenPrice: "0" };
                }
              }),
              last_updated: response.data.lastUpdatedTime,
              marginPercent:
                response.data.summaryInfo.length > 0
                  ? response.data.summaryInfo[0]["margin"]
                  : 0.0,
              notesText:
                response.data.summaryInfo.length > 0
                  ? response.data.summaryInfo[0]["notes"]
                  : "",
              status: false,
              responseReceived: true,
              pricingAccess: JSON.parse(
                sessionStorage.getItem("pricingAccess")
              ),
              goldenPriceAccess: JSON.parse(
                sessionStorage.getItem("goldenPriceAccess")
              ),
            },
            () => {
              this.getAllDetails();
              if (response.data.list.length > 0) {
                this.getShippingOrderDetails();
              }
            }
          );
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummary_OrderSummaryTable,
          "error"
        );
      });
  }

  getShippingOrderDetails() {
    OrderSummaryHandler.getShippingOrder()
      .then((response) => {
        if (response.success && this._isMounted) {
          if (response.data.details !== undefined) {
            let contractor;
            if (response.data.details.Contractor === "") {
              contractor = "";
            } else if (response.data.contractor.length === 0) {
              contractor = {
                label: response.data.details.Contractor,
                value: response.data.details.Contractor,
              };
            } else {
              contractor = response.data.contractor[0];
            }
            this.setState({
              street: response.data.details.Street,
              city: response.data.details.City,
              province: response.data.details.Province,
              postalCode: response.data.details.PostalCode,

              selectedStockingType: response.data.details.StockingType,
              projectOrStockingType: response.data.details.OrderType,
              projectName: response.data.details.Project,
              selectedSpec: response.data.details.SpecAvailable,
              contractorName: contractor,
              selectedAddress:
                response.data.details.SelectedAddress === "true"
                  ? response.data.address[0]
                  : "",
            });
          }
        }
      })

      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_ShippingOrder, "error");
      });
  }

  saveShippingOrderDetails() {
    let data = {
      savedAddressId:
        this.state.selectedAddress !== ""
          ? this.state.selectedAddress.AddressId
          : -1,
      street: this.state.street,
      city: this.state.city,
      province: this.state.province,
      postalCode: this.state.postalCode,
      orderType: this.state.projectOrStockingType,
      stockingType: this.state.selectedStockingType,
      projectName: this.state.projectName,
      contractor: this.state.contractorName.label,
      specAvailable: this.state.selectedSpec,
    };
    OrderSummaryHandler.saveShippingOrderDetails(data).catch((error) => {
      this.addNotification(
        ErrorMessages.OrderSummary_SaveShippingOrderDetails,
        "error"
      );
    });
  }

  getCurrentUser() {
    let currentDate = new Date();
    let month = currentDate.toLocaleString("default", { month: "long" });
    let formattedDate =
      month + " " + currentDate.getDate() + " " + currentDate.getFullYear();
    let userObj = JSON.parse(sessionStorage.getItem("currentUser"));

    ConfigHandler.getDisplayName()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState(
            {
              name: response.message,
              currentRole: userObj["role"],
              date: formattedDate,
            },
            () => {
              this.getFreeShippingDetails();
            }
          );
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_CurrentUser, "error");
      });
  }

  getFreeShippingDetails() {
    if (branchBasedRoles.includes(this.state.currentRole)) {
      this.getBranchFreeShippingDetails();
    } else {
      this.getCustomerFreeShippingDetails();
    }
  }

  getBranchFreeShippingDetails() {
    DistributorManagementHandler.getBranchFreeShippingDetails(
      encodeURIComponent(sessionStorage.getItem("username"))
    ).then((response) => {
      if (response.success && this._isMounted) {
        sessionStorage.setItem("customerName", response.data.tldName);
        this.setState({
          currentBranch: response.data,
          freeShipping: parseFloat(response.data.freeShipping, 2),
          title: response.data.title,
        });
      } else if (this._isMounted) {
        this.setState({
          currentBranch: null,
        });
      }
    });
  }

  getCustomerFreeShippingDetails() {
    let customer = encodeURIComponent(sessionStorage.getItem("customerName"));
    let username = encodeURIComponent(sessionStorage.getItem("username"));

    if (customer !== null) {
      OrderSummaryHandler.getFreeShippingDetails(customer, username).then(
        (response) => {
          if (response.success && this._isMounted) {
            this.setState({
              freeShipping: parseFloat(response.data.freeShipping, 2),
              title: response.data.title,
            });
          }
        }
      );
    }
  }

  getContractor() {
    OrderSummaryHandler.getContractor()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState((prevState) => ({
            contractorList: response.data,
            contractorName:
              prevState.contractorName !== "" &&
              response.data.filter(
                (item) => item.label === prevState.contractorName.label
              ).length > 0
                ? response.data.filter(
                    (item) => item.label === prevState.contractorName.label
                  )[0]
                : prevState.contractorName,
          }));
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_Contractor, "error");
      });
  }

  getProvinces() {
    OrderSummaryHandler.getProvinces()
      .then((response) => {
        if (response.success && this._isMounted) {
          this.setState({
            provinceList: response.data.map((item) => {
              return {
                label: item.ProvinceName,
                value: item.ProvinceName,
              };
            }),
          });
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_Provinces, "error");
      });
  }

  getSavedAddress() {
    let savedAddress = [];
    OrderSummaryHandler.getSavedAddress()
      .then((response) => {
        if (response.success && this._isMounted) {
          response.data.forEach((item) => {
            item.label =
              item.Street +
              ", " +
              item.City +
              ", " +
              item.Province +
              ", " +
              item.PostalCode;
            item.value = item.AddressId;
            savedAddress.push(item);
          });
          this.setState((prevState) => ({
            savedAddress: savedAddress,
            selectedAddress:
              prevState.selectedAddress !== ""
                ? savedAddress.filter(
                    (item) =>
                      item.AddressId === prevState.selectedAddress.AddressId
                  )[0]
                : this.generateSavedAddress(savedAddress),
          }));
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_SavedAddress, "error");
      });
  }

  generateSavedAddress(savedAddress) {
    let newSavedAddress = savedAddress.filter(
      (item) =>
        item.label ===
        this.state.street +
          ", " +
          this.state.city +
          ", " +
          this.state.province +
          ", " +
          this.state.postalCode
    );
    if (newSavedAddress.length > 0) {
      return newSavedAddress;
    } else return "";
  }

  handleDownloadCartClose() {
    this.setState({
      isDownloadCartClicked: false,
    });
  }

  DownloadCartHandler() {
    this.setState({
      isDownloadCartClicked: true,
    });
  }

  updateOrderSummary() {
    this.state.orderSummaryData.forEach((item) => {
      item.Quantity = item.Quantity.toString();
      item.Price = item.Price.toString();
    });

    let data = {
      result: this.state.orderSummaryData,
      status: this.state.status,
      notes: this.state.notesText,
      margin: this.state.marginPercent,
    };
    data.margin = data.margin.toString();

    OrderSummaryHandler.updateOrders(data).catch((error) => {
      this.addNotification(ErrorMessages.OrderSummary_UpdateQuantity, "error");
    });
  }

  getProducts() {
    OrderSummaryHandler.getProducts()
      .then((response) => {
        this.assignPhotoId(response.data);
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_GetImage, "error");
      });
  }

  assignPhotoId(productList) {
    let tempList = this.state.orderSummaryData;
    if (tempList.length > 0) {
      tempList.forEach((element) => {
        if (element.ProductId === "NA") {
          element.url = blankImg;
        } else if (element.PhotoID !== "" && element.PhotoID !== undefined) {
          ProductConfiguratorHandler.getAccessoriesImages(element.PhotoID).then(
            (imagedetails) => {
              element.url = imagedetails[0].src;
              this.setState({ orderSummaryData: tempList });
            }
          );
        } else {
          let prod = productList.find(
            (item) => item.id === parseInt(element.ProductId, 10)
          );
          element.url = prod.featured_src;
        }
        this.setState({ orderSummaryData: tempList });
      });
    }
  }

  getAllDetails() {
    this.getProducts();
    listOfRecords = this.state.orderSummaryData;
    let goldenAvailable = false;
    let customer = "";
    let totalWarehouseFee = 0;
    listOfRecords.forEach((element) => {
      customer = element.CustomerName;

      let saleprice1 = +parseFloat(element.SalePrice);

      let marginAdjustment =
        element.MarginAdjustment === ""
          ? 0
          : parseFloat(element.MarginAdjustment);
      let quantity =
        element.Quantity === "" || isNaN(element.Quantity)
          ? 0
          : parseFloat(element.Quantity);

      let price = saleprice1 === 0 ? parseFloat(element.Price) : saleprice1;
      let goldenPrice = parseFloat(element.GoldenPrice.toString());
      if (goldenPrice !== 0) {
        if (saleprice1 === 0 || goldenPrice <= saleprice1) {
          price = goldenPrice;
          goldenAvailable = true;
          element.Price = goldenPrice;
        }
      }

      element.WarehouseFee = (
        parseFloat(
          parseInt(quantity, 10) % parseInt(element.BaseQuantity, 10)
        ) * parseFloat(element.AdderPrice)
      ).toFixed(2);
      totalWarehouseFee = (
        parseFloat(totalWarehouseFee) + parseFloat(element.WarehouseFee)
      ).toFixed(2);
      element.LeadTime = Miscellaneous.LeadTimeUnavailable;
      element.StockCode = Miscellaneous.StockCodeUnavailable;
      element.PartCategory = "";
      let flag = 0;
      if (element.stockDetails.length > 0) {
        let stockDetails = element.stockDetails.sort(
          (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
        );
        stockDetails.forEach((item) => {
          element.StockCode = item.StockCode;
          if (
            parseInt(element.Quantity, 10) <= parseInt(item.QtyAvailable, 10) &&
            flag === 0
          ) {
            flag = 1;
            element.LeadTime = item.LeadTime;
            element.PartCategory = item.PartCategory;
          }
        });
      }

      element.unitCalculatedPrice = parseFloat(
        (parseFloat(price, 3) * 1) / (1 - marginAdjustment / 100),
        2
      ).toFixed(2);

      element.unitCalculatedPrice = (
        Math.ceil(parseFloat(element.unitCalculatedPrice) * 20) / 20
      ).toFixed(2);

      element.CalculatedPrice = element.unitCalculatedPrice * quantity;

      element.CalculatedPrice = (
        Math.ceil(parseFloat(element.CalculatedPrice) * 20) / 20
      ).toFixed(2);

      element.oldPrice =
        parseFloat(
          parseFloat(element.Price, 3) / (1 - marginAdjustment / 100),
          2
        ).toFixed(2) * quantity;

      element.oldPrice = (
        Math.ceil(parseFloat(element.oldPrice) * 20) / 20
      ).toFixed(2);

      element.unitOldPrice = parseFloat(
        (parseFloat(element.Price, 3) * 1) / (1 - marginAdjustment / 100),
        2
      ).toFixed(2);
      element.unitOldPrice = (
        Math.ceil(parseFloat(element.unitOldPrice) * 20) / 20
      ).toFixed(2);
      element.ViewClicked = false;
    });

    if (this._isMounted) {
      this.setState(
        {
          orderSummaryData: listOfRecords,
          customerName: customer,
          totalWarehouseFee: totalWarehouseFee,
          responseReceived: true,
          GoldenLegend: goldenAvailable,
        },
        () => {
          if (customerBasedRoles.includes(this.state.currentRole)) {
            if (customer) {
              sessionStorage.setItem("customerName", customer);
              this.getCustomerFreeShippingDetails();
            } else {
              sessionStorage.removeItem("customerName");
            }
          }
        }
      );
    }
  }

  render() {
    let notificationStyle = {
      NotificationItem: {
        DefaultStyle: {
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };
    return (
      <div style={{ minWidth: "300px" }} className="estimate-page-container">
        {this.customerInfoSection()}
        {this.orderSummaryCartSection()}
        {this.shippingInformationSection()}
        {this.disclaimerSection()}

        <DownloadPopup
          show={this.state.showExportNamePopup}
          title="Download Estimate Summary"
          format={this.state.format}
          handleOnClose={this.handleCloseNamePopup}
          handleOnSubmit={this.handleSubmitButtonDownload}
          addNotification={this.addNotification}
        />
        <DeleteConfirmationPopup
          show={this.state.showDelete}
          item={this.state.deleteItem.label}
          itemType={this.state.deleteType}
          handleConfirmation={this.handleDeleteItem}
          handleCancel={this.handleDeleteClose}
        />
        <ConfirmationPopUp
          show={this.state.isClearButtonClicked}
          title="Clear Estimate Summary!"
          text={DisplayMessages.OrderSummary_ClearAll}
          clearTableData={this.clearTableData}
          handleClearClose={this.handleClearClose}
        />
        <OkPopUp
          show={this.state.areItemsMissing}
          title="Summary Refreshed!"
          text={
            this.state.orderPinView
              ? DisplayMessages.OrderSummary_PinnedItemsNotAvailable
              : DisplayMessages.OrderSummary_PinnedProductsNotAvailable
          }
          handleOnClose={this.handleOnCloseMissing}
          handleSubmit={this.handleOnCloseMissing}
        />
        <DownloadCartPopup
          show={this.state.isDownloadCartClicked}
          handleOnClose={this.handleDownloadCartClose}
          handleOnSubmit={this.handleSubmitButtonDownload}
          addNotification={this.addNotification}
        />

        <FormalQuotesPopup
          show={this.state.isFormalQuoteClicked}
          customerName={this.state.customerName}
          currentRole={this.state.currentRole}
          notesText={this.state.notesText}
          branchData={
            this.state.currentBranch !== null ? this.state.currentBranch : null
          }
          title={this.state.title}
          name={this.state.name}
          orderType={this.state.projectOrStockingType}
          stockingType={this.state.selectedStockingType}
          projectName={this.state.projectName}
          contractor={this.state.contractorName}
          specAvailable={this.state.selectedSpec}
          userName={sessionStorage.getItem("username")}
          cart={this.getCartItems()}
          address={this.getShippingAddress()}
          handleOnClose={this.handleOnRequestQuoteClose}
          AdditionalNotesChange={this.AdditionalNotesChange}
          addNotification={this.addNotification}
        />
        <SubmitPOPopup
          show={this.state.isSubmitPopupClicked}
          notesText={this.state.notesText}
          name={this.state.name}
          branchData={this.state.currentBranch}
          title={this.state.title}
          orderType={this.state.projectOrStockingType}
          stockingType={this.state.selectedStockingType}
          projectName={this.state.projectName}
          cart={this.getCartItems()}
          shipDate={this.state.desiredShipDate}
          quoteNumber={this.state.quoteNumber}
          contractor={this.state.contractorName}
          specAvailable={this.state.selectedSpec}
          userName={sessionStorage.getItem("username")}
          address={this.getShippingAddress()}
          handleOnClose={this.handleOnSubmitPOClose}
          AdditionalNotesChange={this.AdditionalNotesChange}
          addNotification={this.addNotification}
        />
        <NamePopup
          show={this.state.showPinNamePopup}
          title="Pin Page"
          handleOnClose={this.handleCloseNamePopup}
          handleOnDiscard={this.handleDiscardPinPopup}
          handleOnSubmit={this.pinOrderSummary}
          addNotification={this.addNotification}
        />
        <DownloadProgress show={this.state.downloadLoader} />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  orderSummaryCartSection() {
    return this.state.responseReceived
      ? this.orderSummaryCartDisplaySection()
      : this.orderSummaryLoadingSection();
  }

  orderSummaryLoadingSection() {
    return (
      <div className="cart-loading-section">
        <ContentLoading
          message={DisplayMessages.OrderSummary_Loading}
          size={50}
        />
      </div>
    );
  }

  orderSummaryCartDisplaySection() {
    return this.state.orderSummaryData.length > 0
      ? this.showOrderSummaryCartSection()
      : this.showEmptyCartSection();
  }

  showOrderSummaryCartSection() {
    return (
      <div>
        <Row>
          <Col md={12} sm={12} style={{ marginTop: "5px" }}>
            {this.cartItemsTable()}
            <div>
              <br />
              {this.goldenLegend()}
            </div>
          </Col>
        </Row>
        <Row>{this.additionalNotes()}</Row>
      </div>
    );
  }

  showEmptyCartSection() {
    return (
      <div className="empty-cart loading-fade">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          fill="currentColor"
          class="bi bi-cart-x"
          viewBox="0 0 16 16"
        >
          <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793z" />
          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
        <span>Your cart is empty</span>
      </div>
    );
  }

  SubmitPOButtonHandler() {
    this.setState({
      isSubmitPopupClicked: true,
    });
  }

  handleOnSubmitPOClose() {
    this.setState({
      isSubmitPopupClicked: false,
    });
  }

  goldenLegend() {
    if (
      this.state.orderSummaryData.length > 0 &&
      this.state.GoldenLegend &&
      this.state.pricingAccess &&
      this.state.goldenPriceAccess
    ) {
      return (
        <Row className="loading-fade">
          <span className="gold-pricing">{Miscellaneous.GoldPrice}</span> = Your
          Special Pricing
        </Row>
      );
    } else {
      return null;
    }
  }

  customerInfoSection() {
    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <Col md={6} sm={6} xs={12}>
          <div style={{ fontSize: "14px" }}>
            <Row>
              <Col md={2}>
                <strong>Date:</strong>
              </Col>
              <Col md={10}>{this.state.date}</Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <strong>Name:</strong>
              </Col>
              <Col md={10}>{this.state.name}</Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <strong>Email:</strong>
              </Col>
              <Col md={10}>{sessionStorage.getItem("username")}</Col>
            </Row>
            <br />
            <Row>{this.CustomerOrBranch()}</Row>
          </div>
        </Col>
        {this.state.responseReceived
          ? this.showAdditionalMarginSection()
          : null}
      </div>
    );
  }

  showAdditionalMarginSection() {
    if (this.state.orderSummaryData.length > 0) {
      return (
        <Col
          md={6}
          sm={6}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div hidden={this.hideForDistributorUser()}>
            <ControlLabel>
              {DisplayMessages.OrderSummary_AdditionalMarginText}
            </ControlLabel>

            <table>
              <tbody>
                <tr>
                  <td style={{ width: "70%" }}>
                    <FormControl
                      type="number"
                      min="0"
                      max="99"
                      pattern="[0-9]*"
                      step="0.01"
                      onBlur={this.onBlurUpdateMargin}
                      onChange={this.updateMarginPercent}
                      name="margin"
                      value={this.state.marginPercent}
                      aria-label="Set Margin"
                    />
                  </td>
                  <td style={{ width: "10%", minWidth: "40px" }}>
                    <FormControl
                      readOnly
                      type="text"
                      value="%"
                      aria-label="Percentage"
                    />
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger={["hover", "focus", "click"]}
                      rootClose
                      placement="bottom"
                      overlay={
                        <Tooltip id="globalMarginText">
                          <div>
                            {DisplayMessages.OrderSummary_GlobalMarginText}
                          </div>
                        </Tooltip>
                      }
                    >
                      <Button
                        aria-label="Set Margin"
                        className="button-blue"
                        onClick={this.setAdditionalMargin}
                      >
                        <span className="glyphicon glyphicon-ok" />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span>
            <Button
              className="button-blue-summary"
              bsStyle="primary"
              onClick={this.handlePinPage}
              aria-label="Pin Summary"
            >
              <span className="glyphicon glyphicon-pushpin pin-icon" />
            </Button>
          </span>
        </Col>
      );
    } else return null;
  }

  CustomerOrBranch() {
    if (branchBasedRoles.includes(this.state.currentRole)) {
      return (
        <div>
          <Col md={2}>
            <strong>TLD:</strong>
          </Col>
          <Col md={10}>
            {this.state.currentBranch ? this.state.currentBranch.tldName : ""}
          </Col>
        </div>
      );
    } else {
      return (
        <div>
          <Col md={2}>
            <strong>Customer:</strong>
          </Col>
          <Col md={10}>
            {this.state.customerName ? this.state.customerName : "-"}
          </Col>
        </div>
      );
    }
  }

  additionalNotes() {
    return (
      <div style={{ marginTop: "15px" }}>
        <Col md={6} sm={6}>
          <Panel eventKey={1} key="notes" defaultExpanded>
            <div
              role="menu"
              id="collapsible-panel--heading"
              className="panel-heading"
            >
              <Panel.Heading role="menuitem" aria-labelledby="additionalNotes">
                <Panel.Title>
                  <span className="glyphicon glyphicon-pencil" /> Additional
                  Notes
                </Panel.Title>
              </Panel.Heading>
            </div>
            <FormControl
              componentClass="textarea"
              style={{ resize: "none", borderRadius: "0" }}
              maxLength="500"
              rows="3"
              value={this.state.notesText}
              spellCheck="false"
              placeholder={DisplayMessages.OrderSummary_NotesPlaceHolder}
              onChange={this.modifyNotes}
            />
          </Panel>
        </Col>
        <Col md={6} sm={6}>
          {this.CalculateTotalPrice()}
        </Col>
      </div>
    );
  }

  getShippingAddressFields() {
    return (
      <div>
        <Row className="shipping-details-heading">Shipping Details</Row>
        <Row>
          <h5>Saved Addresses</h5>
          <Col md={10} xs={10} sm={10} style={{ padding: "0px" }}>
            <FormGroup className="visible">
              {this.savedAddressDropdown()}
            </FormGroup>
          </Col>
          <Col
            md={1}
            sm={1}
            xs={1}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0px",
            }}
          >
            <Button
              bsStyle="primary"
              aria-label="Remove"
              className="button-blue glyphicon glyphicon-remove"
              style={{ bottom: "0px", top: "0px" }}
              disabled={this.state.selectedAddress === ""}
              onClick={this.deleteAddress}
            />
          </Col>
          <Col md={1} sm={1} xs={1} />
        </Row>
        <Row>
          <Col
            md={10}
            style={{ textAlign: "center", padding: "0px", fontSize: "14px" }}
          >
            Or create a new address
          </Col>
          <Col md={2} sm={2} xs={2} />
        </Row>
        <Row>
          <Col md={10} style={{ padding: "0px" }}>
            <h5>
              <span
                style={{ color: "red", fontWeight: "bolder" }}
                hidden={this.state.street || this.state.asteriskFirstRun}
              >
                ***{" "}
              </span>
              Street Address
            </h5>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={50}
                value={this.state.street}
                placeholder="Enter Street Address"
                onBlur={this.saveShippingOrderDetails}
                onChange={this.handleStreetChange}
              />
            </FormGroup>
          </Col>
          <Col md={2} sm={2} xs={2} />
        </Row>
        <Row>
          <Col md={10} xs={10} sm={10} style={{ padding: "0px" }}>
            <h5>
              <span
                style={{ color: "red", fontWeight: "bolder" }}
                hidden={this.state.city || this.state.asteriskFirstRun}
              >
                ***{" "}
              </span>
              City
            </h5>
            <FormGroup className="visible">
              <FormControl
                type="text"
                maxLength={20}
                value={this.state.city}
                placeholder="Enter City"
                onBlur={this.saveShippingOrderDetails}
                onChange={this.handleCityChange}
              />
            </FormGroup>
          </Col>
          <Col md={2} sm={2} xs={2} />
        </Row>
        <Row>
          <Col md={10} xs={10} sm={10} style={{ padding: "0px" }}>
            <Col md={6} xs={6} sm={6} style={{ paddingLeft: "0px" }}>
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={this.state.province || this.state.asteriskFirstRun}
                >
                  ***{" "}
                </span>
                {DisplayMessages.OrderSummary_Province}
              </h5>
              <FormGroup className="visible">
                {this.provinceDropdown()}
              </FormGroup>
            </Col>
            <Col md={6} xs={6} sm={6} style={{ paddingRight: "0px" }}>
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.postalCode.length ===
                      Miscellaneous.PostalCodeSize ||
                    this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                {DisplayMessages.OrderSummary_PostalCode}
              </h5>
              <FormGroup className="visible">
                <FormControl
                  type="text"
                  maxLength={Miscellaneous.PostalCodeSize}
                  minLength={Miscellaneous.PostalCodeSize}
                  value={this.state.postalCode}
                  placeholder={
                    DisplayMessages.OrderSummary_PostalCodePlaceholder
                  }
                  onBlur={this.saveShippingOrderDetails}
                  onChange={this.handlePostalCodeChange}
                />
              </FormGroup>
            </Col>
          </Col>
          <Col md={2} sm={2} xs={2} />
        </Row>

        <br />
      </div>
    );
  }

  deleteAddress() {
    this.setState(
      (prevState) => ({
        deleteItem: prevState.selectedAddress,
        deleteType: "Address",
        showDelete: true,
      }),
      () => {
        this.trackDeleteAddressButtonClick();
        this.saveShippingOrderDetails();
      }
    );
  }

  deleteContractor() {
    this.setState(
      (prevState) => ({
        deleteItem: prevState.contractorName,
        deleteType: "Contractor",
        showDelete: true,
      }),
      () => {
        this.trackDeleteContractorButtonClick();
        this.saveShippingOrderDetails();
      }
    );
  }

  handleDeleteAddress() {
    this.trackDeleteAddressButtonConfirmationClick();
    OrderSummaryHandler.deleteAddress(this.state.deleteItem.AddressId)
      .then((response) => {
        if (response.success) {
          this.setState(
            {
              selectedAddress: "",
              street: "",
              city: "",
              province: "",
              postalCode: "",
            },
            () => this.getSavedAddress()
          );
          this.handleDeleteClose();

          this.addNotification(
            DisplayMessages.OrderSummary_DeleteAddress,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_DeleteAddress, "error");
      });
  }

  handleDeleteContractor() {
    this.trackDeleteContractorButtonConfirmationClick();
    OrderSummaryHandler.deleteContractor(this.state.deleteItem.value)
      .then((response) => {
        if (response.success) {
          this.setState(
            {
              contractorName: "",
            },
            () => this.getContractor()
          );
          this.handleDeleteClose();

          this.addNotification(
            DisplayMessages.OrderSummary_DeleteContractor,
            "info"
          );
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummary_DeleteContractor,
          "error"
        );
      });
  }

  handleDeleteItem() {
    if (this.state.deleteType === "Address") {
      this.handleDeleteAddress();
    } else if (this.state.deleteType === "Contractor") {
      this.handleDeleteContractor();
    }
  }

  handleDeleteClose() {
    this.setState({
      showDelete: false,
      deleteItem: "",
      deleteType: "",
    });
  }

  getButtonStatus() {
    let isAddressValid = false;
    if (this.newAddressValidity() || this.selectedAddressValidity()) {
      isAddressValid = true;
    }
    return isAddressValid;
  }

  newAddressValidity() {
    let isNewAddressValid = false;
    if (
      (this.state.street !== undefined && this.state.street.trim() === "") ||
      (this.state.city !== undefined && this.state.city.trim() === "") ||
      this.state.province === "" ||
      this.state.postalCode.length !== Miscellaneous.PostalCodeSize
    ) {
      isNewAddressValid = true;
    }
    return isNewAddressValid;
  }

  selectedAddressValidity() {
    let isSelectedAddressValid = false;
    if (
      this.state.street !== undefined &&
      this.state.selectedAddress.Street === this.state.street.trim() &&
      this.state.city !== undefined &&
      this.state.selectedAddress.City === this.state.city.trim() &&
      this.state.selectedAddress.Province === this.state.province &&
      this.state.selectedAddress.PostalCode === this.state.postalCode
    ) {
      isSelectedAddressValid = true;
    }
    return isSelectedAddressValid;
  }

  saveAddress() {
    let data = {
      addressid:
        this.state.selectedAddress === ""
          ? ""
          : this.state.selectedAddress.AddressId,
      street: this.state.street.trim(),
      city: this.state.city.trim(),
      province: this.state.province,
      postalCode: this.state.postalCode,
    };

    this.trackSaveAddressButtonClick();
    OrderSummaryHandler.saveAddress(data)
      .then((response) => {
        if (response.success) {
          this.addNotification(response.message, "info");
          this.getSavedAddress();
        } else {
          this.addNotification(response.message, "error");
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_SaveAddress, "error");
      });
  }

  handleStreetChange(e) {
    this.setState({
      street: e.target.value,
    });
  }

  handleCityChange(e) {
    this.setState({
      city: e.target.value,
    });
  }

  handlePostalCodeChange(e) {
    let postalCode = e.target.value;
    postalCode = postalCode.replace(/[^a-zA-Z0-9_-]+/g, "");
    this.setState({
      postalCode: postalCode,
    });
  }

  getOrderDetailsFields() {
    return (
      <div>
        <Row
          className="shipping-details-heading"
          style={{ paddingLeft: "15px" }}
        >
          Order Details
        </Row>
        <br />
        {this.actionButtons()}
        {this.cartValueReminder()}
        {this.orderTypeSection()}
        {this.requestQuoteLayout()}
        {this.submitPOLayout()}
        {this.downloadCartLayout()}
      </div>
    );
  }

  shippingInformationSection() {
    return this.state.responseReceived
      ? this.shippingInformationDisplaySection()
      : this.shippingInformationLoadingSection();
  }

  shippingInformationDisplaySection() {
    return (
      <Row className="shipping-details-table loading-fade">
        <Col md={6} sm={6} xs={12} className="order-details-flex col-border">
          <Row>{this.getShippingAddressFields()}</Row>
          <Row className="buttons-row">{this.saveAddressButton()}</Row>
        </Col>
        <Col md={6} sm={6} xs={12} className="order-details-flex">
          <Row>{this.getOrderDetailsFields()}</Row>
          <Row className="buttons-row">{this.nextButton()}</Row>
        </Col>
        <br />
        <br />
      </Row>
    );
  }

  shippingInformationLoadingSection() {
    return null;
  }

  disclaimerSection() {
    return this.state.orderSummaryData.length > 0 ? (
      <Row style={{ marginTop: "0px", marginBottom: "0px" }}>
        <Col md={12} sm={12}>
          <Disclaimer
            last_updated={this.state.last_updated}
            type="OrderSummary"
          />
        </Col>
      </Row>
    ) : null;
  }

  hideForDistributorUser() {
    let isHidden = false;
    if (
      !this.state.pricingAccess &&
      branchBasedRoles.includes(this.state.currentRole)
    ) {
      isHidden = true;
    }
    return isHidden;
  }

  orderTypeSection() {
    if (
      this.state.buttonSelected !== null &&
      this.state.buttonSelected !== orderDetailsButton[0]
    ) {
      return (
        <Row style={{ marginTop: "14px", paddingLeft: "15px" }}>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            <span
              style={{ color: "red", fontWeight: "bolder" }}
              hidden={
                this.state.projectOrStockingType || this.state.asteriskFirstRun
              }
            >
              ***{" "}
            </span>
            Order Type:
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ControlLabel style={{ padding: "10px" }}>
            <input
              type="radio"
              name="radiogroup"
              value="Stocking"
              checked={this.state.projectOrStockingType === "Stocking"}
              onChange={this.handleTypeSelection}
            />{" "}
            Stocking
          </ControlLabel>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ControlLabel style={{ padding: "10px" }}>
            <input
              type="radio"
              name="radiogroup"
              value="Project"
              checked={this.state.projectOrStockingType === "Project"}
              onChange={this.handleTypeSelection}
            />{" "}
            Project
          </ControlLabel>
          <br />
        </Row>
      );
    } else {
      return null;
    }
  }

  actionButtons() {
    return (
      <Row>
        <Col md={4} sm={4} xs={4} hidden={this.hideForDistributorUser()}>
          <Button
            className={
              this.state.buttonSelected === orderDetailsButton[0]
                ? "button-blue active-button"
                : "button-blue"
            }
            style={{ width: "100%" }}
            onClick={() => {
              this.trackRequestQuoteButtonClick();
              this.asteriskUpdate(orderDetailsButton[0]);
            }}
          >
            Request Quote
          </Button>
        </Col>
        <Col md={4} sm={4} xs={4} hidden={this.SubmitPOVisibility()}>
          <Button
            className={
              this.state.buttonSelected === orderDetailsButton[1]
                ? "button-blue active-button"
                : "button-blue"
            }
            style={{ width: "100%" }}
            onClick={() => {
              this.trackSubmitPOButtonClick();
              this.asteriskUpdate(orderDetailsButton[1]);
            }}
          >
            Submit PO
          </Button>
        </Col>
        <Col md={4} sm={4} xs={4}>
          <Button
            className={
              this.state.buttonSelected === orderDetailsButton[2]
                ? "button-blue active-button"
                : "button-blue"
            }
            style={{
              width: "100%",
            }}
            onClick={() => {
              this.trackDownloadCartButtonClick();
              this.asteriskUpdate(orderDetailsButton[2]);
            }}
          >
            Download Cart
          </Button>
        </Col>
      </Row>
    );
  }

  cartValueReminder() {
    if (
      this.state.buttonSelected === orderDetailsButton[0] &&
      parseFloat(preMarginTotal) < 2500
    ) {
      return (
        <div>
          <br />
          <Row style={{ paddingLeft: "15px", fontSize: "15px" }}>
            <span>{DisplayMessages.OrderSummary_CartValueReminderText}</span>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  asteriskUpdate(orderButton) {
    this.setState(
      (prevState) => ({
        asteriskFirstRun: orderButton === orderDetailsButton[2],
        buttonSelected: orderButton,
        projectOrStockingType:
          orderButton === orderDetailsButton[0]
            ? orderTypeList[1]
            : prevState.projectOrStockingType,
      }),
      () => {
        if (orderButton === orderDetailsButton[0]) {
          this.saveShippingOrderDetails();
        }
      }
    );
  }

  SubmitPOVisibility() {
    let isVisible = false;
    if (
      !this.state.pricingAccess ||
      customerBasedRoles.includes(this.state.currentRole)
    ) {
      isVisible = true;
    }
    return isVisible;
  }

  RequiredFieldsFilled() {
    let retValue = true;
    if (!this.state.street) retValue = false;
    if (!this.state.city) retValue = false;
    if (!this.state.province) retValue = false;
    if (!this.state.postalCode) retValue = false;
    if (!this.state.projectOrStockingType) retValue = false;
    if (this.state.buttonSelected === orderDetailsButton[1]) {
      if (!this.state.desiredShipDate) retValue = false;
    }
    if (this.state.projectOrStockingType === "Stocking") {
      if (!this.state.selectedStockingType) retValue = false;
    }
    if (this.state.projectOrStockingType === "Project") {
      if (!this.state.projectName) retValue = false;
      if (!this.state.contractorName) retValue = false;
      if (this.state.buttonSelected === orderDetailsButton[1]) {
        if (!this.state.quoteNumber) retValue = false;
      }
    }
    return retValue;
  }
  getProjectDownloadOptions() {
    if (
      this.state.projectOrStockingType === orderTypeList[1] ||
      this.state.buttonSelected === orderDetailsButton[0]
    ) {
      return (
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Row>
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={this.state.projectName || this.state.asteriskFirstRun}
                >
                  ***{" "}
                </span>
                Project Name
              </h5>
              <FormGroup className="visible">
                <FormControl
                  type="text"
                  maxLength={50}
                  value={this.state.projectName}
                  placeholder="Enter Project Name"
                  onBlur={this.saveShippingOrderDetails}
                  onChange={this.handleProjectNameChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.contractorName || this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                Contractor
              </h5>
              <Creatable
                maxMenuHeight={190}
                value={{
                  value: this.state.contractorName.value,
                  label:
                    this.state.contractorName === null ||
                    this.state.contractorName === ""
                      ? "Select..."
                      : this.state.contractorName.label,
                }}
                styles={customStyles}
                isClearable
                maxLength={51}
                onKeyDown={this.handleContractorInputChange}
                onChange={this.handleContractorChange}
                options={this.state.contractorList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Col>
          </Row>
          <Row style={{ display: "flex", alignItems: "flex-end" }}>
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <Row
                style={{
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  <Button
                    bsStyle="primary"
                    className="button-blue"
                    disabled={
                      this.state.contractorName === "" ||
                      this.state.contractorName.label !==
                        this.state.contractorName.value
                    }
                    style={{ width: "100px" }}
                    onClick={this.saveContractor}
                  >
                    Save
                  </Button>
                </span>
                <span style={{ paddingLeft: "10px" }}>
                  <Button
                    bsStyle="primary"
                    aria-label="Remove"
                    className="button-blue glyphicon glyphicon-remove"
                    disabled={
                      this.state.contractorList.filter(
                        (item) => item === this.state.contractorName
                      ).length === 0
                    }
                    style={{ bottom: "0px", top: "0px" }}
                    onClick={this.deleteContractor}
                  />
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    paddingLeft: "15px",
                  }}
                >
                  {DisplayMessages.OrderSummary_SaveContractorText}
                </span>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "14px" }}>
            <span style={{ fontSize: "14px" }}>
              {DisplayMessages.OrderSummary_SpecText}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ControlLabel>
              <input
                type="radio"
                name="radiogroupspec"
                value="Yes"
                defaultChecked={this.state.selectedSpec === "Yes"}
                onChange={this.handleSpecSelection}
              />{" "}
              Yes
            </ControlLabel>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ControlLabel>
              <input
                type="radio"
                name="radiogroupspec"
                value="No"
                defaultChecked={
                  this.state.selectedSpec === "No" ||
                  this.state.selectedSpec === ""
                }
                onChange={this.handleSpecSelection}
              />{" "}
              No
            </ControlLabel>
            <br />
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  getSubmitPOProjectOptions() {
    if (this.state.projectOrStockingType === orderTypeList[1]) {
      return (
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Row>
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={this.state.projectName || this.state.asteriskFirstRun}
                >
                  ***{" "}
                </span>
                Project Name
              </h5>
              <FormGroup className="visible">
                <FormControl
                  type="text"
                  maxLength={50}
                  value={this.state.projectName}
                  placeholder="Enter Project Name"
                  onBlur={this.saveShippingOrderDetails}
                  onChange={this.handleProjectNameChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              sm={6}
              xs={6}
              style={{ paddingLeft: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.desiredShipDate || this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                Desired Ship Date
              </h5>
              <Row>
                <input
                  type="date"
                  className="date-selection"
                  min={today}
                  style={{
                    width: "100%",
                    height: "32px",
                    border: "1px solid #ccc",
                  }}
                  value={
                    this.state.desiredShipDate ? this.state.desiredShipDate : ""
                  }
                  onChange={this.onShipDateChange}
                />
              </Row>
            </Col>
            <Col
              md={6}
              sm={6}
              xs={6}
              style={{ paddingRight: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={this.state.quoteNumber || this.state.asteriskFirstRun}
                >
                  ***{" "}
                </span>
                Quote Number
              </h5>
              <FormGroup className="visible">
                <FormControl
                  type="text"
                  maxLength={10}
                  value={this.state.quoteNumber}
                  placeholder="Enter Quote Number"
                  onChange={this.handleQuoteNumberChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.contractorName || this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                Contractor
              </h5>
              <Creatable
                maxMenuHeight={190}
                value={{
                  value: this.state.contractorName.value,
                  label:
                    this.state.contractorName === null ||
                    this.state.contractorName === ""
                      ? "Select..."
                      : this.state.contractorName.label,
                }}
                styles={customStyles}
                isClearable
                maxLength={51}
                onKeyDown={this.handleContractorInputChange}
                onChange={this.handleContractorChange}
                options={this.state.contractorList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Col>
          </Row>
          <Row style={{ display: "flex", alignItems: "flex-end" }}>
            <Col
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "0px", alignItems: "flex-end" }}
            >
              <Row
                style={{
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  <Button
                    bsStyle="primary"
                    className="button-blue"
                    disabled={
                      this.state.contractorName === "" ||
                      this.state.contractorName.label !==
                        this.state.contractorName.value
                    }
                    style={{ width: "100px" }}
                    onClick={this.saveContractor}
                  >
                    Save
                  </Button>
                </span>
                <span style={{ paddingLeft: "10px" }}>
                  <Button
                    bsStyle="primary"
                    aria-label="Remove"
                    className="button-blue glyphicon glyphicon-remove"
                    disabled={
                      this.state.contractorList.filter(
                        (item) => item === this.state.contractorName
                      ).length === 0
                    }
                    style={{ bottom: "0px", top: "0px" }}
                    onClick={this.deleteContractor}
                  />
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    paddingLeft: "15px",
                  }}
                >
                  {DisplayMessages.OrderSummary_SaveContractorText}
                </span>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "14px" }}>
            <span style={{ fontSize: "14px" }}>
              {DisplayMessages.OrderSummary_SpecText}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ControlLabel>
              <input
                type="radio"
                name="radiogroupspec"
                value="Yes"
                defaultChecked={this.state.selectedSpec === "Yes"}
                onChange={this.handleSpecSelection}
              />{" "}
              Yes
            </ControlLabel>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ControlLabel>
              <input
                type="radio"
                name="radiogroupspec"
                value="No"
                defaultChecked={
                  this.state.selectedSpec === "No" ||
                  this.state.selectedSpec === ""
                }
                onChange={this.handleSpecSelection}
              />{" "}
              No
            </ControlLabel>
            <br />
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  handleContractorInputChange(e) {
    if (e.target.value.length >= 51) {
      e.target.value = e.target.value.substring(0, 49);
    }
  }

  handleQuoteNumberChange(e) {
    this.setState({
      quoteNumber: e.target.value,
    });
  }

  handleProjectNameChange(e) {
    this.setState({
      projectName: e.target.value,
    });
  }
  handleContractorChange(e) {
    if (e === null) {
      this.setState(
        {
          contractorName: "",
        },
        () => this.saveShippingOrderDetails()
      );
    } else {
      this.setState(
        {
          contractorName: e,
        },
        () => this.saveShippingOrderDetails()
      );
    }
  }
  saveContractor() {
    OrderSummaryHandler.saveContractor(
      encodeURIComponent(this.state.contractorName.label)
    )
      .then((response) => {
        if (response.success) {
          this.addNotification(
            DisplayMessages.OrderSummary_SavedContractor,
            "info"
          );
          this.getContractor();
        } else {
          this.addNotification(response.message, "info");
        }
      })
      .catch((error) => {
        this.addNotification(
          ErrorMessages.OrderSummary_SaveContractor,
          "error"
        );
      });

    this.trackSaveContractorButtonClick();
  }

  getStockingDownloadOptions() {
    if (this.state.projectOrStockingType === orderTypeList[0]) {
      return (
        <Row>
          <Col md={12} sm={12} xs={12}>
            <h5>
              <span
                style={{ color: "red", fontWeight: "bolder" }}
                hidden={
                  this.state.selectedStockingType || this.state.asteriskFirstRun
                }
              >
                ***{" "}
              </span>
              Stocking Type
            </h5>
            <FormGroup className="visible">
              {this.stockingTypeDropDown()}
            </FormGroup>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  getSubmitPOStockingOptions() {
    if (this.state.projectOrStockingType === orderTypeList[0]) {
      return (
        <div>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.selectedStockingType ||
                    this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                Stocking Type
              </h5>
              <FormGroup className="visible">
                {this.stockingTypeDropDown()}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={6}>
              <h5>
                <span
                  style={{ color: "red", fontWeight: "bolder" }}
                  hidden={
                    this.state.desiredShipDate || this.state.asteriskFirstRun
                  }
                >
                  ***{" "}
                </span>
                Desired Ship Date
              </h5>
              <Row>
                <input
                  type="date"
                  className="date-selection"
                  min={today}
                  style={{
                    width: "100%",
                    height: "32px",
                    border: "1px solid #ccc",
                  }}
                  value={
                    this.state.desiredShipDate ? this.state.desiredShipDate : ""
                  }
                  onChange={this.onShipDateChange}
                />
              </Row>
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  onShipDateChange(e) {
    this.setState({
      desiredShipDate: e.target.value,
    });
  }

  stockingTypeDropDown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        value={{
          value: this.state.selectedStockingType,
          label:
            this.state.selectedStockingType === null ||
            this.state.selectedStockingType === ""
              ? "Select..."
              : this.state.selectedStockingType,
        }}
        styles={customStyles}
        onChange={this.handleStockingTypeChange}
        options={stockingTypes}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  handleStockingTypeChange(e) {
    this.setState(
      {
        selectedStockingType: e === null ? "" : e.value,
      },
      () => this.saveShippingOrderDetails()
    );
  }

  provinceDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        value={{
          value: this.state.province,
          label:
            this.state.province === null || this.state.province === ""
              ? "Select..."
              : this.state.province,
        }}
        styles={customStyles}
        onChange={this.handleProvinceChange}
        options={this.state.provinceList}
        aria-label="Provinces"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  handleProvinceChange(e) {
    this.setState(
      {
        province: e === null ? "" : e.value,
      },
      () => this.saveShippingOrderDetails()
    );
  }

  savedAddressDropdown() {
    return (
      <Select
        maxMenuHeight={190}
        menuPlacement="auto"
        isClearable
        value={this.state.selectedAddress}
        styles={customStyles}
        aria-label="Saved Addresses"
        onChange={this.handleAddressChange}
        options={this.state.savedAddress}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "lightgray",
            primary: "lightgray",
          },
        })}
      />
    );
  }

  handleOnCloseMissing() {
    this.setState({ areItemsMissing: false });
  }

  modifyNotes(e) {
    this.setState({ notesText: e.target.value });
  }

  AdditionalNotesChange(notes) {
    this.setState({ notesText: notes });
  }

  onBlurUpdateMargin(e) {
    let qty = e.target.value === "" ? 0.0 : e.target.value;
    qty = parseFloat(qty).toFixed(2);
    this.setState({
      marginPercent: qty,
    });
  }

  updateMarginPercent(e) {
    let qty = e.target.validity.valid
      ? e.target.value
      : this.state.marginPercent;
    qty = parseFloat(qty);
    this.setState({
      marginPercent: qty,
    });
  }

  setAdditionalMargin() {
    listOfRecords = this.state.orderSummaryData;
    listOfRecords.forEach((element) => {
      element.MarginAdjustment = this.state.marginPercent;
    });
    this.trackApplyMarginButtonClick();
    this.setState(
      {
        orderSummaryData: listOfRecords,
      },
      () => this.getAllDetails(),
      this.updateOrderSummary()
    );
  }

  cartItemsTable() {
    return (
      <div
        className="loading-fade"
        ref={(c) => {
          this.selectRef = c;
        }}
      >
        {this.cartItemsHeaders()}
        {this.cartItemsBody()}
      </div>
    );
  }

  cartItemsHeaders() {
    return (
      <Row className="estimate-summary-header">
        <Col
          md={1}
          sm={1}
          xs={1}
          className="estimate-summary-table-cell"
          id="cart-table-products"
        >
          Products
        </Col>
        <Col
          md={!this.hideForDistributorUser() ? 2 : 4}
          sm={!this.hideForDistributorUser() ? 2 : 4}
          xs={!this.hideForDistributorUser() ? 2 : 4}
          className="estimate-summary-table-cell"
          id="cart-table-scd"
        >
          Stock Code / Description
        </Col>
        <Col
          md={!this.hideForDistributorUser() ? 1 : 2}
          sm={!this.hideForDistributorUser() ? 1 : 2}
          xs={!this.hideForDistributorUser() ? 1 : 2}
          className="estimate-summary-table-cell"
          id="cart-table-specs"
        >
          Specs
        </Col>
        <Col
          md={!this.hideForDistributorUser() ? 1 : 2}
          sm={!this.hideForDistributorUser() ? 1 : 2}
          xs={!this.hideForDistributorUser() ? 1 : 2}
          className="estimate-summary-table-cell"
          id="cart-table-quantity"
        >
          Quantity
        </Col>
        {!this.hideForDistributorUser() ? (
          <Col
            md={4}
            sm={4}
            xs={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Col
              md={3}
              sm={3}
              xs={3}
              className="estimate-summary-table-cell"
              id="cart-table-distributor-unit-price"
            >
              Distributor Unit Price
            </Col>
            <Col
              md={3}
              sm={3}
              xs={3}
              className="estimate-summary-table-cell"
              id="cart-table-distributor-margin"
            >
              Distributor Margin
            </Col>
            <Col
              md={3}
              sm={3}
              xs={3}
              className="estimate-summary-table-cell"
              id="cart-table-distributor-sell-price"
            >
              Distributor Sell Price
            </Col>
            <Col
              md={3}
              sm={3}
              xs={3}
              className="estimate-summary-table-cell"
              id="cart-table-distributor-total-sell-price"
            >
              Distributor Total Sell Price
            </Col>
          </Col>
        ) : null}
        <Col
          md={1}
          sm={1}
          xs={1}
          className="estimate-summary-table-cell"
          id="cart-table-estimated-ship-time"
          style={{ minWidth: "60px", flex: "1" }}
        >
          <span>
            Estimated Ship Time
            <br />
            (Based on qty entered)
          </span>
        </Col>
        <Col
          md={1}
          sm={1}
          xs={1}
          className="estimate-summary-table-cell"
          id="cart-table-copy-item"
          style={{ width: "60px" }}
        />
        <Col
          md={1}
          sm={1}
          xs={1}
          className="estimate-summary-table-cell"
          id="cart-table-clear-all"
          style={{ width: "100px" }}
        >
          <Button
            style={{ padding: "8px 8px", border: "1px solid white" }}
            bsStyle="primary"
            className="button-blue"
            onClick={() => this.clearOrderSummaryTable()}
          >
            <span style={{ whiteSpace: "nowrap" }}>Clear All</span>
          </Button>
        </Col>
      </Row>
    );
  }

  cartKey(element) {
    let keyCode =
      element.stockDetails.length > 0
        ? element.stockDetails[0].StockCode
        : Miscellaneous.StockCodeUnavailable;

    let keyDesc =
      element.Wattage === ""
        ? element.Description
        : element.Wattage + "-" + element.Description;

    let fullKey = keyCode + "-" + keyDesc;
    return fullKey;
  }

  cartItemsBody() {
    return (
      <Row style={{ minWidth: "1230px", width: "100%" }}>
        {this.state.orderSummaryData.map((element, i) => {
          if (this.state.orderSummaryData.includes(element)) {
            return (
              <Row
                className="estimate-summary-each-row"
                style={{ minWidth: "1230px", width: "100%" }}
                key={this.cartKey(element)}
              >
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                  style={{ minHeight: "100px" }}
                  id="cart-contents-products"
                >
                  {element.url &&
                  this.state.orderSummaryData.includes(element) ? (
                    <div
                      className="product-image-box"
                      style={{
                        height: "100px",
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={element.url} alt="" className="fit-image" />
                    </div>
                  ) : (
                    <Spinner
                      type="cylon"
                      color="#1B2D5B"
                      height="50px"
                      width="50px"
                    />
                  )}
                </Col>
                <Col
                  id="cart-contents-stock-code"
                  md={!this.hideForDistributorUser() ? 2 : 4}
                  sm={!this.hideForDistributorUser() ? 2 : 4}
                  xs={!this.hideForDistributorUser() ? 2 : 4}
                  className="estimate-summary-table-cell"
                >
                  {element.stockDetails.length > 0
                    ? element.stockDetails[0].StockCode
                    : Miscellaneous.StockCodeUnavailable}
                  <br />{" "}
                  {element.Wattage === ""
                    ? element.Description
                    : element.Wattage + "-" + element.Description}
                </Col>
                <Col
                  id="cart-contents-specs"
                  md={!this.hideForDistributorUser() ? 1 : 2}
                  sm={!this.hideForDistributorUser() ? 1 : 2}
                  xs={!this.hideForDistributorUser() ? 1 : 2}
                  className="estimate-summary-table-cell"
                  onClick={() => this.showSpecLink(element)}
                  style={{ position: "relative" }}
                >
                  <SpecTooltipPopup
                    specValue={element.SpecValue}
                    productId={element.ProductId}
                    stockCodeTitle={
                      element.stockDetails.length > 0
                        ? element.stockDetails[0].StockCode
                        : Miscellaneous.StockCodeUnavailable
                    }
                    descriptionTitle={
                      element.Wattage === ""
                        ? element.Description
                        : element.Wattage + "-" + element.Description
                    }
                    engSpecLink={this.state.engSpecLink}
                    frSpecLink={this.state.frSpecLink}
                    addNotification={this.addNotification}
                    isNavigationEnabled={this.state.itemCategory === "base"}
                    onModal={false}
                  />
                </Col>
                <Col
                  id="cart-contents-quantity"
                  md={!this.hideForDistributorUser() ? 1 : 2}
                  sm={!this.hideForDistributorUser() ? 1 : 2}
                  xs={!this.hideForDistributorUser() ? 1 : 2}
                  className="estimate-summary-table-cell"
                >
                  <FormControl
                    className="set-quantity"
                    type="number"
                    min="0"
                    max="10000"
                    pattern="[0-9]*"
                    name={i}
                    onBlur={(e) => this.onBlurQtyChange(e, element)}
                    onChange={(e) => this.onQtyChange(e, element)}
                    value={element.Quantity}
                    aria-label="Quantity"
                  />
                </Col>
                {!this.hideForDistributorUser() ? (
                  <Col
                    md={4}
                    sm={4}
                    xs={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col
                      id="cart-contents-distributor-unit-price"
                      md={3}
                      sm={3}
                      xs={3}
                      className="estimate-summary-table-cell"
                    >
                      {this.cartElementDistributorUnitPrice(element)}
                    </Col>
                    <Col
                      id="cart-contents-margin-adjustment"
                      md={3}
                      sm={3}
                      xs={3}
                      className="estimate-summary-table-cell"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: "75%", minWidth: "80px" }}>
                              <FormControl
                                type="number"
                                min="0"
                                max="99"
                                pattern="[0-9]*"
                                step="0.01"
                                name={i}
                                onBlur={this.onBlurMarginChange}
                                onChange={this.onMarginChange}
                                value={element.MarginAdjustment}
                                aria-label="Distributor Margin"
                              />
                            </td>
                            <td
                              style={{
                                width: "25%",
                                minWidth: "40px",
                              }}
                            >
                              <FormControl
                                readOnly
                                type="text"
                                value="%"
                                style={{
                                  textAlign: "center",
                                }}
                                aria-label="Percentage"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col
                      id="cart-contents-distributor-sell-price"
                      md={3}
                      sm={3}
                      xs={3}
                      className="estimate-summary-table-cell"
                    >
                      {this.cartDistributorSellPrice(element)}
                    </Col>
                    <Col
                      id="margin-adjusted-total"
                      md={3}
                      sm={3}
                      xs={3}
                      className="estimate-summary-table-cell"
                    >
                      {this.cartDistributorTotalSellPrice(element, false)}
                    </Col>
                  </Col>
                ) : null}
                <Col
                  id="cart-contents-estimated-ship-time"
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                  style={{ minWidth: "60px", flex: "1" }}
                >
                  {element.LeadTime}
                </Col>
                <Col
                  id="cart-contents-copy-item"
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                  style={{ width: "60px" }}
                >
                  <Button
                    onClick={() => this.copyItem(element)}
                    className="glyphicon glyphicon-duplicate"
                    aria-label="Copy to clipboard"
                  />
                </Col>
                <Col
                  id="delete-product"
                  md={1}
                  sm={1}
                  xs={1}
                  className="estimate-summary-table-cell"
                  style={{ width: "100px" }}
                >
                  <Button
                    className="glyphicon glyphicon-remove"
                    aria-label="Remove"
                    style={{
                      padding: 3,
                      backgroundColor: "#1E2D5B",
                      fontSize: "12px",
                      lineHeight: "1",
                    }}
                    bsStyle="primary"
                    onClick={() => this.handleDeleteRow(element)}
                  />
                </Col>
              </Row>
            );
          } else {
            return null;
          }
        })}
      </Row>
    );
  }

  copyItem(element) {
    let totalPrice = this.cartDistributorTotalSellPrice(element, true);

    let description =
      element.Wattage === ""
        ? element.Description
        : element.Wattage + "-" + element.Description;

    let data = {
      description: description,
      productId: element.ProductId.toString(),
    };

    let copyContent = {
      Stock_Code: element.StockCode,
      SKU_Description: description,
      Quantity: parseInt(element.Quantity, 10).toString(),
      Total_Price: totalPrice,
      Availability: element.LeadTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(
      data,
      copyContent,
      this.addNotification,
      "Estimate Summary Cart"
    );
  }

  cartElementDistributorUnitPrice(element) {
    if (parseFloat(element.SalePrice) === 0) {
      return (
        <div>
          {parseFloat(element.GoldenPrice) === 0 ? (
            <span>${parseFloat(element.Price).toFixed(2)}</span>
          ) : (
            <div className="gold-pricing">
              <span>${parseFloat(element.GoldenPrice).toFixed(2)}</span>
            </div>
          )}
        </div>
      );
    } else if (
      parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
      parseFloat(element.GoldenPrice) === 0
    ) {
      return (
        <div>
          <div
            style={{
              textDecorationLine: "line-through",
            }}
          >
            ${parseFloat(element.Price).toFixed(2)}
          </div>
          <div
            style={{
              color: "red",
              fontWeight: "bolder",
            }}
          >
            ${parseFloat(element.SalePrice).toFixed(2)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="gold-pricing">
          <span>${parseFloat(element.GoldenPrice).toFixed(2)}</span>
        </div>
      );
    }
  }

  cartDistributorSellPrice(element) {
    if (parseFloat(element.SalePrice) === 0) {
      return (
        <div>
          {parseFloat(element.GoldenPrice) === 0 ? (
            <span>
              {"$" +
                (element.CalculatedPrice === 0
                  ? "0.00"
                  : parseFloat(element.unitCalculatedPrice).toFixed(2))}
            </span>
          ) : (
            <div className="gold-pricing">
              <span>
                {"$" +
                  (element.CalculatedPrice === 0
                    ? "0.00"
                    : parseFloat(element.unitCalculatedPrice).toFixed(2))}
              </span>
            </div>
          )}
        </div>
      );
    } else if (
      parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
      parseFloat(element.GoldenPrice) === 0
    ) {
      return (
        <div>
          <div
            style={{
              textDecorationLine: "line-through",
            }}
          >
            {"$" +
              (element.oldPrice === 0
                ? "0.00"
                : parseFloat(element.unitOldPrice).toFixed(2))}
          </div>
          <div
            style={{
              color: "red",
              fontWeight: "bolder",
            }}
          >
            {"$" +
              (element.CalculatedPrice === 0
                ? "0.00"
                : parseFloat(element.unitCalculatedPrice).toFixed(2))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="gold-pricing">
          <div>
            {"$" +
              (element.CalculatedPrice === 0
                ? "0.00"
                : parseFloat(element.unitCalculatedPrice).toFixed(2))}
          </div>
        </div>
      );
    }
  }

  cartDistributorTotalSellPrice(element, isCopyToClipboard) {
    return isCopyToClipboard
      ? this.getCartDistributorTotalSellPrice(element)
      : this.showCartDistributorTotalSellPrice(element);
  }

  getCartDistributorTotalSellPrice(element) {
    let totalPrice = "";
    totalPrice =
      element.CalculatedPrice === 0
        ? "0.00"
        : parseFloat(element.CalculatedPrice).toFixed(2);

    totalPrice += element.WarehouseFee > 0 ? " + " + element.WarehouseFee : "";

    return totalPrice;
  }

  showCartDistributorTotalSellPrice(element) {
    return (
      <div>
        {parseFloat(element.SalePrice) === 0 ? (
          <div>
            {parseFloat(element.GoldenPrice) === 0 ? (
              <span>
                {"$" +
                  (element.CalculatedPrice === 0
                    ? "0.00"
                    : parseFloat(element.CalculatedPrice).toFixed(2))}
              </span>
            ) : (
              <div className="gold-pricing">
                <span>
                  {"$" +
                    (element.CalculatedPrice === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div>
            {parseFloat(element.SalePrice) < parseFloat(element.GoldenPrice) ||
            parseFloat(element.GoldenPrice) === 0 ? (
              <div>
                <div
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  {"$" +
                    (element.oldPrice === 0
                      ? "0.00"
                      : parseFloat(element.oldPrice).toFixed(2))}
                </div>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bolder",
                  }}
                >
                  {"$" +
                    (element.CalculatedPrice === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </div>
              </div>
            ) : (
              <div className="gold-pricing">
                <div>
                  {"$" +
                    (element.CalculatedPrice === 0
                      ? "0.00"
                      : parseFloat(element.CalculatedPrice).toFixed(2))}
                </div>
              </div>
            )}
          </div>
        )}{" "}
        {element.WarehouseFee > 0 ? " + $" + element.WarehouseFee : ""}
      </div>
    );
  }

  onBlurMarginChange(e) {
    let orderSummaryData = [...this.state.orderSummaryData];
    let index = parseInt(e.target.name, 10);
    let qty = e.target.value === "" ? 0 : e.target.value;
    orderSummaryData[index].MarginAdjustment = parseFloat(qty).toFixed(2);
    this.setState(
      { orderSummaryData: orderSummaryData },
      () => this.getAllDetails(),
      this.updateOrderSummary()
    );
  }

  onMarginChange(e) {
    let orderSummaryData = [...this.state.orderSummaryData];
    let index = parseInt(e.target.name, 10);
    orderSummaryData[index].MarginAdjustment = e.target.validity.valid
      ? e.target.value
      : orderSummaryData[index].MarginAdjustment;
    this.setState({ orderSummaryData: orderSummaryData }, () =>
      this.getAllDetails()
    );
  }

  viewButtonClicked(index) {
    let listOfOrders = this.state.orderSummaryData;
    listOfOrders[index].ViewClicked = !listOfOrders[index].ViewClicked;
    this.setState({
      orderSummaryData: listOfOrders,
    });
  }

  onBlurQtyChange(e, element) {
    let orderSummaryData = [...this.state.orderSummaryData];
    let qty = e.target.value === "" ? 1 : e.target.value;
    let index = parseInt(e.target.name, 10);
    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.stockDetails.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    orderSummaryData[index].Quantity = parseFloat(qty);
    orderSummaryData[index].LeadTime = shipTime;

    this.setState(
      { orderSummaryData: orderSummaryData },
      () => this.getAllDetails(),
      this.updateOrderSummary()
    );
  }

  onQtyChange(e, element) {
    let orderSummaryData = [...this.state.orderSummaryData];
    let index = parseInt(e.target.name, 10);
    let qty = e.target.validity.valid
      ? e.target.value
      : orderSummaryData[index].Quantity;

    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.stockDetails.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );
    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    orderSummaryData[index].Quantity = parseFloat(qty);
    orderSummaryData[index].LeadTime = shipTime;

    this.setState({ orderSummaryData: orderSummaryData }, () =>
      this.getAllDetails()
    );
  }

  CalculateTotalPrice() {
    let preMarginTotalPrice = 0.0;
    totalprice = 0.0;
    let totalWarehouseFee = 0.0;
    this.state.orderSummaryData.forEach((element, i) => {
      totalprice =
        parseFloat(totalprice, 2) +
        parseFloat(element.CalculatedPrice, 2) +
        parseFloat(element.WarehouseFee, 2);

      if (parseFloat(element.SalePrice) === 0) {
        if (parseFloat(element.GoldenPrice) === 0) {
          preMarginTotalPrice =
            parseFloat(preMarginTotalPrice, 2) +
            parseFloat(element.Price * element.Quantity, 2) +
            parseFloat(element.WarehouseFee, 2);
        } else {
          preMarginTotalPrice =
            parseFloat(preMarginTotalPrice, 2) +
            parseFloat(element.GoldenPrice * element.Quantity, 2) +
            parseFloat(element.WarehouseFee, 2);
        }
      } else {
        if (
          parseFloat(element.GoldenPrice) === 0 ||
          parseFloat(element.GoldenPrice) > parseFloat(element.SalePrice)
        ) {
          preMarginTotalPrice =
            parseFloat(preMarginTotalPrice, 2) +
            parseFloat(element.SalePrice * element.Quantity, 2) +
            parseFloat(element.WarehouseFee, 2);
        } else {
          preMarginTotalPrice =
            parseFloat(preMarginTotalPrice, 2) +
            parseFloat(element.GoldenPrice * element.Quantity, 2) +
            parseFloat(element.WarehouseFee, 2);
        }
      }
      totalWarehouseFee = (
        parseFloat(totalWarehouseFee) + parseFloat(element.WarehouseFee)
      ).toFixed(2);
    });
    preMarginTotal = preMarginTotalPrice;
    if (this.hideForDistributorUser()) return null;
    return (
      <div style={{ float: "right", paddingRight: "50px", textAlign: "right" }}>
        {totalWarehouseFee > 0 ? (
          <h5>
            Includes ${totalWarehouseFee} of warehouse fees for repackaging.
          </h5>
        ) : null}
        <span className="subtotal-text">
          Subtotal: ${parseFloat(totalprice).toFixed(2)}
        </span>
        <br />
        {this.state.freeShipping <= preMarginTotalPrice ? (
          <h5 style={{ fontWeight: "bold" }}>
            Qualified for free shipping
            <span
              className="glyphicon glyphicon-ok-sign"
              style={{ fontSize: "15px", paddingLeft: "5px", color: "#99b83c" }}
            />
          </h5>
        ) : (
          <div>
            <h5 style={{ fontWeight: "bold" }}>
              Add $
              {parseFloat(
                parseFloat(this.state.freeShipping, 2) -
                  parseFloat(preMarginTotalPrice, 2)
              ).toFixed(2)}{" "}
              more to qualify for free shipping.
            </h5>
            <h5 style={{ fontWeight: "bold", fontSize: "10px" }}>
              (Before margin adjustment)
            </h5>
          </div>
        )}
      </div>
    );
  }
  handleDeleteRow(element) {
    let allOrders = this.state.orderSummaryData;
    allOrders = allOrders.filter((item) => item !== element);
    if (allOrders.length <= 0) {
      this.setState({
        notesText: "",
        marginPercent: 0.0,
      });
    }
    OrderSummaryHandler.clearOneOrderRecord(
      element.Description,
      element.Wattage,
      element.SetId
    )
      .then((response) => {
        let goldenLegend = false;
        allOrders.forEach((item) => {
          let price =
            parseFloat(item.SalePrice) === 0 ? item.Price : item.SalePrice;
          let goldenPrice = item.GoldenPrice.toString();
          if (
            parseFloat(goldenPrice) !== 0 &&
            parseFloat(goldenPrice) <= parseFloat(price)
          ) {
            goldenLegend = true;
          }
        });
        this.setState(
          {
            orderSummaryData: allOrders,
            GoldenLegend: goldenLegend,
          },
          () => {
            this.trackDeleteItemButtonClick(element);
          }
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_OrderRecord, "error");
      });
  }

  saveAddressButton() {
    return (
      <Row>
        <Col md={4} sm={4} xs={4} style={{ padding: "0px" }}>
          <h5>Save address to drop-down menu?</h5>
          <Button
            bsStyle="primary"
            className="button-blue"
            style={{ width: "100%" }}
            disabled={this.getButtonStatus()}
            onClick={this.saveAddress}
          >
            Save Address
          </Button>
        </Col>
      </Row>
    );
  }

  nextButton() {
    let showTooltip = false;
    let tooltipMessage = "";
    if (this.state.buttonSelected === orderDetailsButton[0]) {
      showTooltip =
        this.state.orderSummaryData.length === 0 ||
        !this.RequiredFieldsFilled() ||
        parseFloat(preMarginTotal) < 2500
          ? true
          : false;
      tooltipMessage =
        parseFloat(preMarginTotal) < 2500
          ? DisplayMessages.OrderSummary_CartValueReminderText
          : tooltipMessage;
      tooltipMessage = this.RequiredFieldsFilled()
        ? tooltipMessage
        : DisplayMessages.OrderSummary_RequiredFields;
      tooltipMessage =
        this.state.orderSummaryData.length === 0
          ? "Please add items to cart before requesting quotes for SKUs"
          : tooltipMessage;
    } else if (this.state.buttonSelected === orderDetailsButton[1]) {
      showTooltip = !this.RequiredFieldsFilled();
      tooltipMessage = this.RequiredFieldsFilled()
        ? tooltipMessage
        : DisplayMessages.OrderSummary_RequiredFields;
    }

    return (
      <Row>
        <Col md={4} sm={4} xs={4} hidden={this.state.buttonSelected === null}>
          {showTooltip ? (
            <OverlayTrigger
              placement="top"
              trigger={["click", "hover", "focus"]}
              rootClose
              overlay={
                <Tooltip id="orderDetailsNextButton">{tooltipMessage}</Tooltip>
              }
            >
              <Button
                bsStyle="primary"
                className="button-blue"
                style={{ width: "100%" }}
                onClick={() => this.trackBlockedNextButtonClick(tooltipMessage)}
              >
                {"Next ->"}
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              bsStyle="primary"
              className="button-blue"
              style={{ width: "100%" }}
              onClick={this.onNextButtonClicked}
            >
              {"Next ->"}
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  onNextButtonClicked() {
    if (this.state.buttonSelected === orderDetailsButton[0]) {
      this.trackRequestQuoteNextButtonClick();
      this.getFormalQuote();
    } else if (this.state.buttonSelected === orderDetailsButton[1]) {
      this.trackSubmitPONextButtonClick();
      this.SubmitPOButtonHandler();
    } else {
      this.trackDownloadCartNextButtonClick();
      this.DownloadCartHandler();
    }
  }

  requestQuoteLayout() {
    if (this.state.buttonSelected === orderDetailsButton[0]) {
      return <div>{this.getProjectDownloadOptions()}</div>;
    } else {
      return null;
    }
  }

  submitPOLayout() {
    if (this.state.buttonSelected === orderDetailsButton[1]) {
      return (
        <div>
          {this.getSubmitPOStockingOptions()}
          {this.getSubmitPOProjectOptions()}
        </div>
      );
    } else {
      return null;
    }
  }

  downloadCartLayout() {
    if (this.state.buttonSelected === orderDetailsButton[2]) {
      return (
        <div>
          {this.getStockingDownloadOptions()}
          {this.getProjectDownloadOptions()}
        </div>
      );
    } else {
      return null;
    }
  }

  clearOrderSummaryTable() {
    this.trackClearAllButtonClick();
    this.setState({
      isClearButtonClicked: true,
    });
  }

  handleClearClose() {
    this.setState({ isClearButtonClicked: false });
  }

  clearTableData() {
    OrderSummaryHandler.clearOrderSummary()
      .then((response) => {
        this.setState(
          {
            orderSummaryData: [],
            notesText: "",
            marginPercent: 0.0,
            customerName: "",
            responseReceived: false,
            GoldenLegend: false,
          },
          this.getAllDetails
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.OrderSummary_OrderSummary, "error");
      });
    this.setState({
      isClearButtonClicked: false,
      selectedAddress: "",
      street: "",
      city: "",
      province: "",
      postalCode: "",
      selectedStockingType: "",
      selectedSpec: "No",
      projectOrStockingType: "",
      projectName: "",
      contractorName: "",
    });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  pinOrderSummary(pinName) {
    let orderDetails = {
      orderType: this.state.projectOrStockingType,
      projectName: this.state.projectName,
      stockingType: this.state.selectedStockingType,
      contractor:
        this.state.contractorName === "" ? "" : this.state.contractorName.label,
      specAvailable: this.state.selectedSpec,
      quoteNumber: this.state.quoteNumber,
      desiredShipDate: this.state.desiredShipDate,
    };

    let modifiedOrderSummary = this.state.orderSummaryData.map((item) => {
      return { ...item, Price: item.Price.toString() };
    });

    let jsonData = {
      name: this.state.name,
      date: this.state.date,
      email: sessionStorage.getItem("username"),
      customerInfo: branchBasedRoles.includes(this.state.currentRole)
        ? this.ternaryEvaluation(
            this.state.currentBranch,
            true,
            this.state.currentBranch.tldName,
            "-"
          )
        : this.ternaryEvaluation(
            sessionStorage.getItem("customerName"),
            null,
            "-",
            sessionStorage.getItem("customerName")
          ),
      customer: branchBasedRoles.includes(this.state.currentRole)
        ? "TLD:"
        : "Customer:",

      orderSummaryData: modifiedOrderSummary,
      marginPercent: this.state.marginPercent.toString(),
      notesText: this.state.notesText,
      address: this.getShippingAddress(),
      orderDetails: orderDetails,
    };
    const data = {
      title: pinName,
      redirectPage: "PinnedOrderSummary",
      parameters: jsonData,
    };

    OrderSummaryHandler.postPinPage(data)
      .then((res) => {
        if (res.success) {
          this.trackPinPageSubmitClick(pinName);
          this.addNotification(DisplayMessages.OrderSummary_PinnedMsg, "info");
        } else {
          this.trackPinPageFailedClick(pinName, res.data.message);
          this.addNotification(res.data.message, "warning");
        }
      })
      .catch((error) => {
        this.trackPinPageFailedClick(
          pinName,
          ErrorMessages.OrderSummary_PinnedMsg
        );
        this.addNotification(ErrorMessages.OrderSummary_PinnedMsg, "error");
      });
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  handleDiscardPinPopup() {
    this.trackPinPageDiscardClick();
    this.handleCloseNamePopup();
  }

  handleCloseNamePopup() {
    this.setState({
      showPinNamePopup: false,
      showExportNamePopup: false,
    });
  }

  handleSelect(name) {
    this.setState({
      format: name,
    });
    this.downloadOrderSummary();
  }

  downloadOrderSummary() {
    this.setState({ status: true });
    let flag = this.allFieldsFilled();
    if (!flag) {
      this.addNotification(
        ErrorMessages.OrderSummary_ValidQuantityValues,
        "error"
      );
      return;
    }
    this.setState({
      showExportNamePopup: true,
    });
  }

  handleSubmitButtonDownload(
    fileName,
    hasPrice,
    hasNotes,
    hasLeadTime,
    format
  ) {
    this.setState({
      downloadLoader: true,
    });

    let updatedOrderSummary = this.state.orderSummaryData.map((item) => {
      return {
        ...item,
        Price: item.Price.toString(),
      };
    });

    let userData = {
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      email: sessionStorage.getItem("username"),
      phoneNumber: sessionStorage.getItem("phoneNumber"),
    };

    let list = {
      orderSummaryData: updatedOrderSummary,
      totalprice: parseFloat(totalprice).toFixed(2),
      customername: this.state.customerName,
      format: format,
      includePrice: hasPrice,
      includeNotes: hasNotes,
      includeLeadTime: hasLeadTime,
      notesText: this.state.notesText ? this.state.notesText : "NA",
      branch: this.state.currentBranch
        ? this.state.currentBranch.branchName
        : "NA",
      tldName: this.state.currentBranch
        ? this.state.currentBranch.tldName
        : "NA",
      orderType: this.state.projectOrStockingType,
      address: this.getShippingAddress(),
      projectName: this.state.projectName,
      contractorName:
        this.state.contractorName === "" ? "" : this.state.contractorName.label,
      stockingType: this.state.selectedStockingType,
      specAvailable: this.state.selectedSpec,
      userData: userData,
    };

    OrderSummaryHandler.exportOrderSummary(list)
      .then((response) => {
        this.setState({
          downloadLoader: false,
        });
        this.trackDownloadCartSuccessClick(fileName);
        const url = window.URL.createObjectURL(new Blob([response]));
        fileName += list.format === "Excel" ? ".xlsx" : ".pdf";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.setState({
          downloadLoader: false,
        });
        this.trackDownloadCartFailedClick(
          fileName,
          ErrorMessages.OrderSummary_Download
        );
        this.addNotification(ErrorMessages.OrderSummary_Download, "error");
      });
  }

  handleTypeSelection(e) {
    this.setState(
      {
        projectOrStockingType: e.target.value,
      },
      () => this.saveShippingOrderDetails()
    );
  }

  handleSpecSelection(e) {
    this.setState(
      {
        selectedSpec: e.currentTarget.value,
      },
      () => this.saveShippingOrderDetails()
    );
  }

  handleAddressChange(e) {
    if (e === null) {
      this.setState(
        {
          selectedAddress: "",
          street: "",
          city: "",
          province: "",
          postalCode: "",
        },
        () => this.saveShippingOrderDetails()
      );
    } else {
      this.setState(
        {
          selectedAddress: e,
          street: e.Street,
          city: e.City,
          province: e.Province,
          postalCode: e.PostalCode,
        },
        () => this.saveShippingOrderDetails()
      );
    }
  }

  getShippingAddress() {
    let address = "";
    let tempAdd = [];
    if (this.state.street) tempAdd.push(this.state.street);
    if (this.state.city) tempAdd.push(this.state.city);
    if (this.state.province) tempAdd.push(this.state.province);
    if (this.state.postalCode) tempAdd.push(this.state.postalCode);
    if (tempAdd.length === 0) address = "NA";
    else address = tempAdd.toString();
    return address;
  }

  getCartItems() {
    let ordersummary = {
      orderSummaryData: this.state.orderSummaryData,
      totalprice: parseFloat(totalprice).toFixed(2),
      customername: this.state.customerName,
      format: "Excel",
      includePrice: true,
      includeNotes: true,
      includeIcons: true,
      includeLeadTime: true,
      notesText: this.state.notesText ? this.state.notesText : "NA",
      branch: this.state.currentBranch
        ? this.state.currentBranch.branchName
        : "NA",
      tldName: this.state.currentBranch
        ? this.state.currentBranch.tldName
        : "NA",
      orderType: this.state.projectOrStockingType,
      address: this.getShippingAddress(),
      projectName: this.state.projectName,
      contractorName:
        this.state.contractorName === ""
          ? "NA"
          : this.state.contractorName.label,
      stockingType: this.state.selectedStockingType,
      specAvailable: this.state.selectedSpec,
    };
    return ordersummary;
  }

  handleOnRequestQuoteClose() {
    this.setState({
      isFormalQuoteClicked: false,
    });
  }

  getFormalQuote() {
    if (this.state.orderSummaryData.length === 0) {
      return;
    }
    this.setState({ status: true });
    let flag = this.allFieldsFilled();
    if (!flag) {
      this.addNotification(
        ErrorMessages.OrderSummary_ValidQuantityValues,
        "error"
      );
      return;
    }
    this.setState({
      isFormalQuoteClicked: true,
    });
  }

  handlePinPage() {
    this.setState({ status: true });
    let flag = this.allFieldsFilled();
    this.trackPinButtonClick();
    if (!flag) {
      this.addNotification(
        ErrorMessages.OrderSummary_ValidQuantityValues,
        "error"
      );
      return;
    }
    this.setState({
      showPinNamePopup: true,
    });
  }

  allFieldsFilled() {
    let flag = true;
    this.state.orderSummaryData.forEach((item, i) => {
      if (
        item.Quantity === "" ||
        parseInt(item.Quantity, 10) === 0 ||
        parseInt(item.Quantity, 10) > 10000
      )
        flag = false;
    });
    return flag;
  }

  showSpecLink(element) {
    let specLinkPopupStockCode =
      element.stockDetails.length > 0
        ? element.stockDetails[0].StockCode
        : Miscellaneous.StockCodeUnavailable;
    let specLinkPopupDescription =
      element.Wattage === ""
        ? element.Description
        : element.Wattage + "-" + element.Description;
    let productId = element.ProductId;
    let specValue = element.SpecValue;
    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory: element.productId !== "NA" ? "base" : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOnPopupClose() {
    this.setState({
      showSpecLinkPopup: false,
    });
  }

  // Mixpanel Tracking //

  trackApplyMarginButtonClick() {
    mixpanel.track("Additional Margin Adjustment", {
      Action: "Button Click",
      Effect: "Update additional margin for all cart items",
      "Margin Percent": this.state.marginPercent,
    });
  }

  trackPinButtonClick() {
    mixpanel.track("Pin Content", {
      Action: "Button Click",
      Effect: "Current estimate summary cart will be pinned",
      Content: "Estimate Summary Cart",
    });
  }

  trackPinPageDiscardClick() {
    mixpanel.track("Discard Pin Content", {
      Action: "Button Click",
      Effect: "Current estimate summary cart will not be pinned",
      Content: "Estimate Summary Cart",
    });
  }

  trackPinPageSubmitClick(pinName) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current estimate summary cart is pinned",
      Content: "Estimate Summary Cart",
      "Pinned Name": pinName,
    });
  }

  trackPinPageFailedClick(pinName, message) {
    mixpanel.track("Submit Pin Content", {
      Action: "Button Click",
      Effect: "Current estimate summary cart is not pinned",
      Content: "Estimate Summary Cart",
      "Pinned Name": pinName,
      Message: message,
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }

  trackClearAllButtonClick() {
    mixpanel.track("Clear Cart", {
      Action: "Button Click",
      Effect: "Current cart will be cleared",
    });
  }

  trackDeleteItemButtonClick(element) {
    mixpanel.track("Clear Item from Cart", {
      Action: "Button Click",
      Effect: "Current item will be cleared from cart",
      Description: element.Description,
      Wattage: element.Wattage,
      SetId: element.SetId,
    });
  }

  trackRequestQuoteButtonClick() {
    mixpanel.track("Request Quote", {
      Action: "Button Click",
      Effect: "Request Quote details will be displayed",
    });
  }

  trackSubmitPOButtonClick() {
    mixpanel.track("Submit PO", {
      Action: "Button Click",
      Effect: "Submit PO details will be displayed",
    });
  }

  trackDownloadCartButtonClick() {
    mixpanel.track("Download Cart", {
      Action: "Button Click",
      Effect: "Download Cart details will be displayed",
    });
  }

  trackSaveContractorButtonClick() {
    mixpanel.track("Save Contractor", {
      Action: "Button Click",
      Effect: "Newly created contractor will be saved",
      Contractor: this.state.contractorName.label,
    });
  }

  trackSaveAddressButtonClick() {
    mixpanel.track("Save Address", {
      Action: "Button Click",
      Effect: "Current address will be saved",
      Address:
        this.state.selectedAddress === ""
          ? ""
          : this.state.selectedAddress.AddressId,
      Street: this.state.street,
      City: this.state.city,
      Province: this.state.province,
      "Postal Code": this.state.postalCode,
    });
  }

  trackDeleteContractorButtonClick() {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected contractor will be deleted",
      "Item Type": "Contractor",
      Contractor: this.state.contractorName
        ? this.state.contractorName.label
        : "",
    });
  }

  trackDeleteContractorButtonConfirmationClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected contractor deleted",
      "Item Type": "Contractor",
      Contractor: this.state.deleteItem.label,
    });
  }

  trackDeleteAddressButtonClick() {
    mixpanel.track("Delete Item", {
      Action: "Button Click",
      Effect: "Selected address will be deleted",
      "Item Type": "Address",
      Address: this.state.selectedAddress
        ? this.state.selectedAddress.label
        : "",
    });
  }

  trackDeleteAddressButtonConfirmationClick() {
    mixpanel.track("Delete Confirmation", {
      Action: "Button Click",
      Effect: "Selected address deleted",
      "Item Type": "Address",
      Address: this.state.deleteItem.label,
    });
  }

  trackRequestQuoteNextButtonClick() {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Display popup for request quote",
      Name: "Request Quote",
      "Project Name": this.state.projectName,
      Contractor: this.state.contractorName.label,
      "Spec Available": this.state.selectedSpec,
      "Popup Opened": true,
    });
  }

  trackDownloadCartNextButtonClick() {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Display popup for download cart",
      Name: "Download Cart",
      "Order Type": this.state.projectOrStockingType,
      "Stocking Type": this.state.selectedStockingType,
      "Project Name": this.state.projectName,
      Contractor: this.state.contractorName.label,
      "Spec Available": this.state.selectedSpec,
      "Popup Opened": true,
    });
  }

  trackSubmitPONextButtonClick() {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Display popup for submit PO",
      Name: "Submit PO",
      "Order Type": this.state.projectOrStockingType,
      "Stocking Type": this.state.selectedStockingType,
      "Project Name": this.state.projectName,
      "Desired Ship Date": this.state.desiredShipDate,
      "Quote Number": this.state.quoteNumber,
      Contractor: this.state.contractorName.label,
      "Spec Available": this.state.selectedSpec,
      "Popup Opened": true,
    });
  }

  trackBlockedNextButtonClick(message) {
    mixpanel.track("Next - Blocked", {
      Action: "Button Click",
      Effect: "Display selected order popup",
      "Popup Opened": false,
      Message: message,
    });
  }

  trackDownloadCartSuccessClick(filename) {
    mixpanel.track("Download Cart Confirmation", {
      Action: "Button Click",
      Effect: "Cart downloaded successfully",
      Filename: filename,
    });
  }

  trackDownloadCartFailedClick(filename, message) {
    mixpanel.track("Next", {
      Action: "Button Click",
      Effect: "Cart downloaded failed",
      Filename: filename,
      Message: message,
    });
  }
}

export default OrderSummary;
