import React, { Component } from "react";

import { FormControl, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import Select from "react-select";
import Creatable from "react-select/creatable";

import NotificationSystem from "react-notification-system";
import mixpanel from "mixpanel-browser";

import "./searchbox.css";
import "./searchList.css";
import ConfigHandler from "../ConfigHandler/ConfigHandler";
import ProductConfiguratorHandler from "../ProductConfigurator/ProductConfiguratorHandler";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import {
  DisplayMessages,
  ErrorMessages,
  Miscellaneous,
  fetchJSONFileData,
} from "../Utils/FetchConfigurableData";
import fixedLink from "../Assets/fixedAssets.json";
import SpecTooltipPopup from "../SpecTooltipPopup/SpecTooltipPopup";
import ContentLoading from "../ContentLoading/ContentLoading";
import { CopyToClipboard } from "../Utils/CopyToClipboard";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "#555",

    "&:hover": { backgroundColor: "lightgray" },
    padding: 10,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "gray" },

    border: "1px solid lightgray",
    borderRadius: "0px",
    boxShadow: "none",
    minHeight: "34px",
    height: "34px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "34px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "34px",
    padding: "0 6px",
  }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "lightgrey",
    },
    "::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "10px",
    },
  }),
};
const productTypes = ["led", "construction"];
const branchBasedRoles = ["Distributor User", "Branch Admin"];

const allCustomColors = [
  { Title: "BRZ", Name: "Bronze", className: " bronze" },
  { Title: "BLK", Name: "Matte Black", className: " matte-black" },
  { Title: "MBK", Name: "Mirror Black", className: " mirror-black" },
  { Title: "SBK", Name: "Sandtex Black", className: " black-sandtex" },
  { Title: "WHT", Name: "White", className: " white" },
  { Title: "HGY", Name: "Hammertone", className: " hammertone" },

  { Title: "DGY", Name: "Dove Gray", className: " dove-gray" },
  { Title: "SPS", Name: "Sparkle Silver", className: " sparkle-silver" },
  { Title: "DGN", Name: "Design Green", className: " green" },
  { Title: "GGN", Name: "Garden Green", className: " garden-green" },
  { Title: "BLU", Name: "Blue", className: " blue" },
  { Title: "YLW", Name: "Yellow", className: " yellow" },
  { Title: "RED", Name: "Red", className: " red" },
  { Title: "GRY", Name: "Gray", className: " gray" },
];

let customerName = "";
let customerTLD = "";

/**
 * Component represents the search results list page for stock code and description search
 */
class SearchList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      searchItemList: [],
      sortedSearchItemList: [],
      searchString: "",
      oldSearchString: "",
      searchItem: null,
      isLoading: false,
      customerList: [],
      currentRole: "",
      customerTier: "Tier1",
      pricingAccess: true,
      selectedCustomer: null,
      tierSelected: "Tier1",
      singleCustomer: false,
      customerTLD: "",
      quantity: 1,
      isNewCustomer: false,
      showAllSKUs: true,
      stockCodeSort: false,
      descriptionSort: null,
      totalPriceSort: null,
      partCategorySort: null,
      specsSort: null,
      showConfirmationPopup: false,
      selectedElement: null,
      message: "",
      tierList: Miscellaneous.TiersList,
      showSpecLinkPopup: false,
      specLinkPopupStockCode: "",
      specLinkPopupDescription: "",
      specValue: "default",
      engSpecLink: "",
      frSpecLink: "",
      itemCategory: "accessories",
    };
    this._notificationSystem = null;

    this.addNotification = this.addNotification.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.handleSingleCustomerSelect =
      this.handleSingleCustomerSelect.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.onBlurQuantityHandler = this.onBlurQuantityHandler.bind(this);
    this.onForceBlurQuantityHandler =
      this.onForceBlurQuantityHandler.bind(this);
    this.quantityChangedHandler = this.quantityChangedHandler.bind(this);
    this.toggleSortSetting = this.toggleSortSetting.bind(this);
    this.sortIconClickHandler = this.sortIconClickHandler.bind(this);
    this.onItemQuantityChange = this.onItemQuantityChange.bind(this);
    this.onItemQuantityBlur = this.onItemQuantityBlur.bind(this);
    this.searchListQuantityChange = this.searchListQuantityChange.bind(this);
    this.handleTierSelect = this.handleTierSelect.bind(this);
    this.setElementPrice = this.setElementPrice.bind(this);
    this.updateSearchStringHandler = this.updateSearchStringHandler.bind(this);
    this.searchByStockCode = this.searchByStockCode.bind(this);
    this.searchByDescription = this.searchByDescription.bind(this);
    this.getUpdatedItemsList = this.getUpdatedItemsList.bind(this);
    this.showSpecLink = this.showSpecLink.bind(this);
    this.handleOnPopupClose = this.handleOnPopupClose.bind(this);
    this.getSpecLinks = this.getSpecLinks.bind(this);
    this.generateTable = this.generateTable.bind(this);
    this.copyItem = this.copyItem.bind(this);
    this.handleStockCodeSort = this.handleStockCodeSort.bind(this);
    this.handleDescriptionSort = this.handleDescriptionSort.bind(this);
    this.handleSpecSort = this.handleSpecSort.bind(this);
    this.handlePriceSort = this.handlePriceSort.bind(this);
    this.handlePartCategorySort = this.handlePartCategorySort.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    await fetchJSONFileData(fixedLink.displayMessagesPath);
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";

    this.generateTable();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.updateState(nextProps);
        }
      );
    }
  }

  ternaryEvaluation(value1, value2, condition1, condition2) {
    return value1 === value2 ? condition1 : condition2;
  }

  generateTable() {
    let role = sessionStorage.getItem("userRole");
    if (this._isMounted) {
      this.setState(
        {
          currentRole: role,
          pricingAccess: JSON.parse(sessionStorage.getItem("pricingAccess")),
          isLoading: true,
          tierList: Miscellaneous.TiersList,
        },
        () => {
          if (branchBasedRoles.includes(role)) {
            this.setState({
              singleCustomer: true,
            });
          }
          this.updateState(this.props);
          this.getCustomers();
        }
      );
    }
  }

  render() {
    return this.state.isLoading
      ? this.searchListLoadingSection()
      : this.searchListSection();
  }

  searchListLoadingSection() {
    return (
      <div className="search-result-container">
        <br />
        <Row>{this.searchResultsText()}</Row>
        <br />
        <ContentLoading
          message={DisplayMessages.SearchList_Loading}
          size={50}
        />
      </div>
    );
  }

  searchListSection() {
    return this.state.searchItemList !== undefined &&
      this.state.searchItemList.length > 0
      ? this.searchListDisplaySection()
      : this.searchListEmptySection();
  }

  searchListEmptySection() {
    return (
      <div className="search-result-container loading-fade">
        <br />
        <Row>
          <h3
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {DisplayMessages.SearchList_NoProducts}
          </h3>
        </Row>
      </div>
    );
  }

  searchListDisplaySection() {
    let notificationStyle = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          padding: "30px",
          wordBreak: "break-word",
        },
      },
    };

    return (
      <div className="search-result-container loading-fade">
        <br />
        <Row>{this.searchResultsText()}</Row>
        <br />
        <Row style={{display: "inline"}}>
          <Col md={8} xs={8} sm={8} style={{ padding: "0px" }}>
            {this.CustomerDropdownSection()}
          </Col>
          <Col md={4} xs={4} sm={4} style={{ padding: "0px" }}>
            {this.setQuantitySection()}
          </Col>
        </Row>
        <br />
        <Row>{this.sortSettingsSection()}</Row>
        <Row>{this.searchResultTableSection()}</Row>
        <ConfirmationPopUp
          show={this.state.showConfirmationPopup}
          title="Clear Estimate Summary!"
          text={this.state.message}
          clearTableData={this.clearTableData.bind(this)}
          handleClearClose={this.handleClearClose.bind(this)}
        />
        <NotificationSystem
          ref={(notificationSystem) =>
            (this._notificationSystem = notificationSystem)
          }
          style={notificationStyle}
        />
      </div>
    );
  }

  clearTableData() {
    ProductConfiguratorHandler.clearOrderSummary()
      .then((response) => {
        if (response.success) {
          this.addItem(this.state.selectedElement);
        }
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.UnableToClear, "error");
      });
    this.setState({ showConfirmationPopup: false });
  }

  handleClearClose() {
    this.setState({ showConfirmationPopup: false });
  }

  hideForDistributorUser() {
    let isHidden = false;
    if (
      !this.state.pricingAccess &&
      branchBasedRoles.includes(this.state.currentRole)
    ) {
      isHidden = true;
    }
    return isHidden;
  }

  getCustomers() {
    ProductConfiguratorHandler.getCustomerList().then((response) => {
      let customer = response.data.customers.list.find(
        (item) => item.CustomerName === customerName
      );
      if (response.success && this._isMounted) {
        this.setState(
          {
            selectedCustomer: customerName,
            isNewCustomer: customer === undefined && customerName !== "",
            tierSelected: this.setTierSelected(response),
            customerTier: this.setCustomerTier(customer),
            customerList: response.data.customers.list.map((item) => ({
              value:
                item.CustomerName +
                "-tier-" +
                item.Tier +
                "-tier-" +
                item.ConstructionTier,
              label: item.CustomerName,
            })),
            customerTLD: this.setCustomerTLD(response),
          },
          () => {
            if (
              this.state.singleCustomer &&
              this.state.customerList.length > 0
            ) {
              customerName = this.state.customerTLD;
              this.handleSingleCustomerSelect(
                this.state.customerTLD,
                this.state.customerList[0]
              );
            } else {
              this.setElementPrice();
            }
          }
        );
      }
    });
  }

  setTierSelected(response) {
    return customerName !== ""
      ? response.data.customers.tier
      : this.state.tierList[0];
  }

  setCustomerTier(customer) {
    return customer === undefined || customer.Tier === "1"
      ? this.state.tierList[0]
      : this.ternaryEvaluation(
          customer.ConstructionTier,
          "1",
          this.state.tierList[1],
          this.state.tierList[2]
        );
  }

  setCustomerTLD(response) {
    return response.data.customers.list.length === 1
      ? response.data.customers.list[0].TLDName
      : "";
  }

  handleCustomerSelect(event) {
    if (event === null) {
      customerName = "";
      sessionStorage.setItem("customerName", customerName);
      this.trackCustomerSelection(customerName);
      this.setState(
        (prevState) => ({
          isNewCustomer: false,
          selectedCustomer: customerName,
          customerTier: prevState.tierList[0],
        }),
        this.setElementPrice
      );
      return;
    }

    let customerInfo = event.value.split("-tier-");
    if (customerInfo.length > 1) {
      sessionStorage.setItem("customerName", customerInfo[0]);
      this.trackCustomerSelection(customerInfo[0]);
      this.setState(
        (prevState) => ({
          isNewCustomer: false,
          selectedCustomer: customerInfo[0],
          customerTier:
            customerInfo[1] === "1"
              ? prevState.tierList[0]
              : this.ternaryEvaluation(
                  customerInfo[2],
                  "1",
                  prevState.tierList[1],
                  prevState.tierList[2]
                ),
        }),
        this.setElementPrice
      );
    } else {
      sessionStorage.setItem("customerName", event.value);
      this.trackCustomerSelection(event.value);
      this.setState(
        (prevState) => ({
          isNewCustomer: true,
          selectedCustomer: event.value,
          customerTier: prevState.tierList[0],
          tierSelected: prevState.tierList[0],
        }),
        this.setElementPrice
      );
    }
    customerName =
      sessionStorage.getItem("customerName") !== null
        ? sessionStorage.getItem("customerName")
        : "";
  }

  handleTierSelect(e) {
    let tier = e.value;

    ProductConfiguratorHandler.setCustomerTier(tier)
      .then((response) => {
        if (!response.success) {
          this.addNotification(
            ErrorMessages.CustomerTierNotRegistered,
            "error"
          );
        }
        this.trackCustomerTierSelection(tier);
        this.setState(
          {
            tierSelected: tier,
            customerTier: tier,
          },
          this.setElementPrice
        );
      })
      .catch((error) => {
        this.addNotification(ErrorMessages.CustomerTierMissing, "error");
      });
  }

  handleSingleCustomerSelect(tld, customer) {
    let customerInfo = customer.value.split("-tier-");
    sessionStorage.setItem("customerName", tld);
    customerName = customer.label;
    customerTLD = tld;

    this.setState(
      (prevState) => ({
        isNewCustomer: true,
        selectedCustomer: customer.label,
        customerTier:
          customerInfo[1] === "1"
            ? prevState.tierList[0]
            : this.ternaryEvaluation(
                customerInfo[2],
                "1",
                prevState.tierList[1],
                prevState.tierList[2]
              ),
      }),
      this.setElementPrice
    );
  }

  updateSearchStringHandler(input) {
    if (this._isMounted) {
      this.setState({
        searchString: input,
      });
    }
  }

  updateState(newProps) {
    let params = newProps.location.state;
    if (!params && this._isMounted) {
      this.setState({
        isLoading: false,
      });
    } else if (this._isMounted) {
      let item = params.item;
      let input = params.s;
      let searchType = params.searchType;
      this.updateSearchStringHandler(input);

      if (searchType === 1) {
        this.searchByDescription(item, input);
      } else {
        this.searchByStockCode(item, input);
      }
    }
  }

  searchByStockCode(item, input) {
    let searchString = "";
    if (item.value === "0") {
      searchString = input;
      ConfigHandler.stockCodeSearch(encodeURIComponent(searchString))
        .then((response) => {
          if (response.success && this._isMounted) {
            let list = response.data;
            this.setState(
              {
                isLoading: false,
                searchString: searchString,
                searchItemList: list,
                sortedSearchItemList: list,
              },
              this.setElementPrice
            );
          }
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.SearchList_StockCodeSearch,
            "error"
          );
        });
    } else {
      searchString = item.label.toLowerCase().includes(input.toLowerCase())
        ? item.label
        : item.value;
      ConfigHandler.stockCodeSearch(searchString)
        .then((response) => {
          if (response.success && this._isMounted) {
            let list = response.data;
            this.setState(
              {
                isLoading: false,
                searchString: searchString,
                searchItemList: list,
                sortedSearchItemList: list,
              },
              this.setElementPrice
            );
          }
        })
        .catch((error) => {
          this.addNotification(
            ErrorMessages.SearchList_StockCodeSearch,
            "error"
          );
        });
    }
  }

  searchByDescription(item, input) {
    item.value === "0"
      ? this.emptyDescriptionSearch(input)
      : this.normalDescriptionSearch(item, input);
  }

  emptyDescriptionSearch(input) {
    let searchString = input;
    ConfigHandler.descriptionSearch(encodeURIComponent(searchString))
      .then((response) => {
        if (response.success && this._isMounted) {
          let list = response.data;
          this.setState(
            {
              isLoading: false,
              searchString: searchString,
              searchItemList: list,
              sortedSearchItemList: list,
            },
            this.setElementPrice
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            searchString: searchString,
            searchItemList: [],
            sortedSearchItemList: [],
          });
        }
        this.addNotification(
          ErrorMessages.SearchList_DescriptionSearch,
          "error"
        );
      });
  }

  normalDescriptionSearch(item, input) {
    let searchString = item.label.toLowerCase().includes(input.toLowerCase())
      ? item.label
      : item.value;

    ConfigHandler.descriptionSearch(encodeURIComponent(searchString))
      .then((response) => {
        if (response.success && this._isMounted) {
          let list = response.data.filter(
            (element) => element.description === searchString
          );
          this.setState(
            {
              isLoading: false,
              searchString: searchString,
              searchItemList: list,
              sortedSearchItemList: list,
            },
            this.setElementPrice
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            searchString: searchString,
            searchItemList: [],
            sortedSearchItemList: [],
          });
        }
        this.addNotification(
          ErrorMessages.SearchList_DescriptionSearch,
          "error"
        );
      });
  }

  searchResultTableSection() {
    return (
      <div>
        {this.searchResultTableHeader()}
        {this.searchResultTableBody()}
      </div>
    );
  }

  handleStockCodeSort(e) {
    this.setState(
      (prevState) => ({
        stockCodeSort:
          prevState.stockCodeSort === null ? true : !prevState.stockCodeSort,
        descriptionSort: null,
        totalPriceSort: null,
        partCategorySort: null,
        specsSort: null,
      }),
      this.sortIconClickHandler(e, this.state.stockCodeSort)
    );
  }

  handleDescriptionSort(e) {
    this.setState(
      (prevState) => ({
        descriptionSort:
          prevState.descriptionSort === null
            ? true
            : !prevState.descriptionSort,
        stockCodeSort: null,
        totalPriceSort: null,
        partCategorySort: null,
        specsSort: null,
      }),
      this.sortIconClickHandler(e, this.state.descriptionSort)
    );
  }

  handleSpecSort(e) {
    this.setState(
      (prevState) => ({
        specsSort: prevState.specsSort === null ? true : !prevState.specsSort,
        descriptionSort: null,
        stockCodeSort: null,
        totalPriceSort: null,
        partCategorySort: null,
      }),
      this.sortIconClickHandler(e, this.state.specsSort)
    );
  }

  handlePriceSort(e) {
    this.setState(
      (prevState) => ({
        totalPriceSort:
          prevState.totalPriceSort === null ? true : !prevState.totalPriceSort,
        stockCodeSort: null,
        descriptionSort: null,
        partCategorySort: null,
        specsSort: null,
      }),
      this.sortIconClickHandler(e, this.state.totalPriceSort)
    );
  }

  handlePartCategorySort(e) {
    this.setState(
      (prevState) => ({
        partCategorySort:
          prevState.partCategorySort === null
            ? true
            : !prevState.partCategorySort,
        stockCodeSort: null,
        descriptionSort: null,
        totalPriceSort: null,
        specsSort: null,
      }),
      this.sortIconClickHandler(e, this.state.partCategorySort)
    );
  }

  searchResultTableHeader() {
    return (
      <Row className="search-result-row-header">
        <Col md={1} sm={1} xs={1} className="search-result-row-headcell">
          Stock Code
          <span
            id="stockCode"
            className="sort-icon-default"
            onClick={this.handleStockCodeSort}
          >
            <span
              id="stockCode"
              className={
                this.state.stockCodeSort === null
                  ? "glyphicon glyphicon-triangle-top "
                  : ""
              }
            />
            <span
              id="stockCode"
              className={this.sortIconDesigner(this.state.stockCodeSort)}
              style={{
                paddingLeft: "5px",
              }}
            />
          </span>
        </Col>
        <Col md={3} sm={3} xs={3} className="search-result-row-headcell">
          Description
          <span
            id="description"
            className="sort-icon-default"
            onClick={this.handleDescriptionSort}
          >
            <span
              id="description"
              className={
                this.state.descriptionSort === null
                  ? "glyphicon glyphicon-triangle-top"
                  : ""
              }
            />
            <span
              id="description"
              className={this.sortIconDesigner(this.state.descriptionSort)}
              style={{
                paddingLeft: "5px",
              }}
            />
          </span>
        </Col>
        <Col
          md={!this.hideForDistributorUser() ? 1 : 2}
          sm={!this.hideForDistributorUser() ? 1 : 2}
          xs={!this.hideForDistributorUser() ? 1 : 2}
          className="search-result-row-headcell"
        >
          Specs
          <span
            id="specValue"
            className="sort-icon-default"
            onClick={this.handleSpecSort}
          >
            <span
              id="specValue"
              className={
                this.state.specsSort === null
                  ? "glyphicon glyphicon-triangle-top"
                  : ""
              }
            />
            <span
              id="specValue"
              className={this.sortIconDesigner(this.state.specsSort)}
              style={{
                paddingLeft: "5px",
              }}
            />
          </span>
        </Col>
        {!this.hideForDistributorUser() ? (
          <Col md={1} sm={1} xs={1} className="search-result-row-headcell">
            Total Price
            <span
              id="price"
              className="sort-icon-default"
              onClick={this.handlePriceSort}
            >
              <span
                id="price"
                className={
                  this.state.totalPriceSort === null
                    ? "glyphicon glyphicon-triangle-top"
                    : ""
                }
              />
              <span
                id="price"
                className={this.sortIconDesigner(this.state.totalPriceSort)}
                style={{
                  paddingLeft: "5px",
                }}
              />
            </span>
          </Col>
        ) : null}
        <Col md={1} sm={1} xs={1} className="search-result-row-headcell">
          Qty
        </Col>
        <Col md={2} sm={2} xs={2} className="search-result-row-headcell">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Estimated Ship Time</span>
            <span style={{ fontSize: "12px" }}>(based on qty entered)</span>
          </div>
        </Col>
        <Col md={2} sm={2} xs={2} className="search-result-row-headcell">
          Part Category
          <span
            className="sort-icon-default"
            id="status"
            onClick={this.handlePartCategorySort}
          >
            <span
              id="status"
              className={
                this.state.partCategorySort === null
                  ? "glyphicon glyphicon-triangle-top"
                  : ""
              }
            />
            <span
              id="status"
              className={this.sortIconDesigner(this.state.partCategorySort)}
              style={{
                paddingLeft: "5px",
              }}
            />
          </span>
        </Col>
        <Col md={1} sm={1} xs={1} className="search-result-row-headcell">
          Add To Cart
        </Col>
      </Row>
    );
  }

  sortIconDesigner(sortState) {
    if (sortState === null) {
      return "glyphicon glyphicon-triangle-bottom";
    } else if (sortState) {
      return "glyphicon glyphicon-triangle-bottom";
    } else {
      return "glyphicon glyphicon-triangle-top";
    }
  }

  sortIconClickHandler(e, sortEnabled) {
    let id = e.target.id;
    let itemList = [];

    if (sortEnabled) {
      itemList = this.state.sortedSearchItemList.sort(function (a, b) {
        if (id === "price") {
          let newId1 = this.ternaryEvaluation(
            a["salePrice"],
            0,
            id,
            "salePrice"
          );
          let newId2 = this.ternaryEvaluation(
            b["salePrice"],
            0,
            id,
            "salePrice"
          );
          return a[newId1] > b[newId2] ? 1 : -1;
        } else {
          return a[id] > b[id] ? 1 : -1;
        }
      });
    } else {
      itemList = this.state.sortedSearchItemList.sort(function (a, b) {
        if (id === "price") {
          let newId1 = this.ternaryEvaluation(
            a["salePrice"],
            0,
            id,
            "salePrice"
          );
          let newId2 = this.ternaryEvaluation(
            b["salePrice"],
            0,
            id,
            "salePrice"
          );
          return a[newId1] > b[newId2] ? -1 : 1;
        } else {
          return a[id] > b[id] ? -1 : 1;
        }
      });
    }

    this.trackSortSearchResults(id, sortEnabled);
    this.setState({
      sortedSearchItemList: itemList,
    });
  }

  searchResultTableBody() {
    return (
      <div
        className="scrollable-div"
        style={{ height: "600px", minHeight: "500px" }}
      >
        <Row>
          {this.state.sortedSearchItemList?.map((element, i) => {
            return (
              <Row className="search-result-popup" key={element.stockCode}>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="search-result-row-cell "
                  id="search-stockcode"
                >
                  {element.stockCode}
                </Col>
                <Col
                  md={3}
                  sm={3}
                  xs={3}
                  className="search-result-row-cell"
                  id="search-description"
                >
                  {element.description}
                </Col>
                <Col
                  md={!this.hideForDistributorUser() ? 1 : 2}
                  sm={!this.hideForDistributorUser() ? 1 : 2}
                  xs={!this.hideForDistributorUser() ? 1 : 2}
                  className="search-result-row-cell"
                  id="search-specs"
                  onClick={() => this.showSpecLink(element)}
                >
                  <SpecTooltipPopup
                    specValue={element.specValue}
                    productId={element.productId}
                    stockCodeTitle={element.stockCode}
                    descriptionTitle={element.description}
                    engSpecLink={this.state.engSpecLink}
                    frSpecLink={this.state.frSpecLink}
                    addNotification={this.addNotification}
                    isNavigationEnabled={this.state.itemCategory === "base"}
                    onModal={false}
                  />
                </Col>
                {!this.hideForDistributorUser() ? (
                  <Col
                    md={1}
                    sm={1}
                    xs={1}
                    className="search-result-row-cell"
                    id="search-price"
                  >
                    {this.totalSKUPrice(element, false)}
                  </Col>
                ) : null}
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="search-result-row-cell"
                  id="search-quantity"
                >
                  <FormControl
                    type="number"
                    min="1"
                    max="10000"
                    pattern="[0-9]*"
                    style={{ width: "150px" }}
                    value={element.itemQuantity}
                    onBlur={(e) => this.onItemQuantityBlur(e, element)}
                    onChange={(e) => this.onItemQuantityChange(e, element)}
                    aria-label="Quantity"
                  />
                </Col>
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  className="search-result-row-cell"
                  id="search-leadtime"
                >
                  {element.shipTime}
                </Col>
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  className="search-result-row-cell"
                  id="search-partCategory"
                >
                  {element.status === "B"
                    ? Miscellaneous.PartCategoryStocked
                    : Miscellaneous.PartCategoryNotStocked}
                </Col>
                <Col
                  md={1}
                  sm={1}
                  xs={1}
                  className="search-result-row-cell copy-or-add"
                  id="search-add-to-cart"
                >
                  <Button
                    onClick={() => this.copyItem(element)}
                    className="glyphicon glyphicon-duplicate"
                    aria-label="Copy to clipboard"
                  />
                  <Button
                    onClick={() => this.addItem(element)}
                    aria-label="Add Item"
                    className="glyphicon glyphicon-plus"
                  />
                </Col>
              </Row>
            );
          })}
        </Row>
      </div>
    );
  }

  totalSKUPrice(element, isCopyToClipboard) {
    let qty = isNaN(element.itemQuantity)
      ? 1
      : +parseFloat(element.itemQuantity).toFixed(2);

    let salePrice = +parseFloat(element.salePrice) * qty;
    let price = +parseFloat(element.price) * qty;
    let goldenPrice = +parseFloat(element.goldenPrice) * qty;
    let goldenPriceAccess = JSON.parse(
      sessionStorage.getItem("goldenPriceAccess")
    );

    return isCopyToClipboard
      ? this.getTotalSKUPrice(salePrice, price, goldenPrice, goldenPriceAccess)
      : this.showTotalSKUPrice(
          salePrice,
          price,
          goldenPrice,
          goldenPriceAccess
        );
  }

  getTotalSKUPrice(salePrice, price, goldenPrice, goldenPriceAccess) {
    if (salePrice === 0) {
      return goldenPrice === 0 || !goldenPriceAccess
        ? price.toFixed(2)
        : goldenPrice.toFixed(2);
    } else {
      return goldenPrice === 0 || goldenPrice > salePrice || !goldenPriceAccess
        ? salePrice.toFixed(2)
        : goldenPrice.toFixed(2);
    }
  }

  showTotalSKUPrice(salePrice, price, goldenPrice, goldenPriceAccess) {
    if (salePrice === 0) {
      if (goldenPrice === 0 || !goldenPriceAccess) {
        return <div>$ {price.toFixed(2)}</div>;
      } else {
        return <div className="gold-pricing">$ {goldenPrice.toFixed(2)}</div>;
      }
    } else if (
      goldenPrice === 0 ||
      goldenPrice > salePrice ||
      !goldenPriceAccess
    ) {
      return (
        <div>
          <div style={{ textDecorationLine: "line-through" }}>
            $ {price.toFixed(2)}
          </div>
          <div style={{ color: "red" }}>{salePrice.toFixed(2)}</div>
        </div>
      );
    } else {
      return <div className="gold-pricing">$ {goldenPrice.toFixed(2)}</div>;
    }
  }

  setElementPrice() {
    let sortedList = this.state.sortedSearchItemList;
    let searchList = this.state.searchItemList;

    sortedList = this.getUpdatedItemsList(sortedList);
    searchList = this.getUpdatedItemsList(searchList);
    if (this._isMounted) {
      this.setState({
        sortedSearchItemList: sortedList,
        searchItemList: searchList,
      });
    }
  }

  getUpdatedListWithGoldenPrice(list) {
    let customerNameForGoldenPrice = this.state.selectedCustomer;
    if (
      customerNameForGoldenPrice != null &&
      customerNameForGoldenPrice.length > 0
    ) {
      list = list.map((item) => {
        let goldenCustomer = item.goldenList.find(
          (x) => x.customerName === customerNameForGoldenPrice
        );
        if (goldenCustomer !== undefined) {
          return { ...item, goldenPrice: parseFloat(goldenCustomer.unitPrice) };
        } else {
          return { ...item, goldenPrice: 0 };
        }
      });
    } else {
      list = list.map((item) => {
        return { ...item, goldenPrice: 0 };
      });
    }

    return list;
  }

  getUpdatedItemsList(list) {
    list = this.getUpdatedListWithGoldenPrice(list);

    list = list.map((item) => {
      if (item.productType === productTypes[0]) {
        if (this.state.customerTier === this.state.tierList[0]) {
          return { ...item, price: item.price1 };
        } else {
          return { ...item, price: item.price2 };
        }
      } else if (this.state.customerTier === this.state.tierList[2]) {
        return { ...item, price: item.price2 };
      } else {
        return { ...item, price: item.price1 };
      }
    });

    return list;
  }

  copyItem(element) {
    let totalPrice = this.totalSKUPrice(element, true);

    let data = {
      description: element.description,
      productId: element.productId.toString(),
    };

    let copyContent = {
      Stock_Code: element.stockCode,
      SKU_Description: element.description,
      Quantity: parseInt(element.itemQuantity, 10).toString(),
      Total_Price: this.state.pricingAccess ? totalPrice : "NA",
      Availability: element.shipTime,
      Spec_Sheet_English: "NA",
      Spec_Sheet_French: "NA",
    };

    CopyToClipboard(data, copyContent, this.addNotification, "Search Results");
  }

  addItem(element) {
    let product_details = [];
    let construction_details = [];
    let accessory_details = [];
    if (
      this.state.selectedCustomer === null ||
      this.state.selectedCustomer.trim() === ""
    ) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameMissing);
      this.addNotification(ErrorMessages.CustomerNameMissing, "warning");
      return;
    }
    if (this.state.selectedCustomer.length > 50) {
      this.trackAddToCartButtonClick(ErrorMessages.CustomerNameLength);
      this.addNotification(ErrorMessages.CustomerNameLength, "warning");
    } else {
      let wattage =
        element.pricelist !== "" && element.pricelist.Wattage
          ? element.pricelist.Wattage
          : null;

      let description =
        wattage !== null
          ? element.description.replace(wattage + "-", "")
          : element.description;

      let tempArray = description.split(" ");
      let tempColor = "";
      tempArray.map((item) => {
        tempColor = allCustomColors.filter(
          (x) => x.Title.toString() === item.toString()
        );
        return tempColor;
      });
      if (element.productType === "led") {
        if (wattage) {
          product_details.push({
            description: description.split("-"),
            wattage: wattage ? wattage : "",
            cstColor: "CST",
            Quantity: element.itemQuantity,
            setId: element.pricelist === "" ? "" : element.pricelist.SetId,
          });
        } else {
          accessory_details.push({
            color: tempColor[0] !== undefined ? tempColor[0]["Title"] : "",
            Accessories:
              tempColor.length > 0
                ? description.replace(tempColor[0]["Title"], "CST")
                : description,
            Quantity: element.itemQuantity,
            setId: element.pricelist === "" ? "" : element.pricelist.SetId,
          });
        }
      } else {
        construction_details.push({
          color: "",
          Title: description,
          Quantity: element.itemQuantity,
          setId: element.pricelist === "" ? "" : element.pricelist.SetId,
        });
      }

      let data = {
        title: "Search",
        productId:
          element.pricelist === ""
            ? element.productId
            : element.pricelist.ProductId,
        action: "SearchSummary",
        setId: element.pricelist === "" ? "" : element.pricelist.SetId,
        customer_name:
          customerTLD !== ""
            ? customerTLD
            : this.ternaryEvaluation(
                this.state.selectedCustomer,
                null,
                "",
                this.state.selectedCustomer
              ),

        orderlist: {
          product_list: product_details,
          constructionOrAccessory_list:
            element.productType === "led"
              ? accessory_details
              : construction_details,
          quickShip_list: [],
          goldenItemsList: [],
        },
      };
      ProductConfiguratorHandler.addToOrderSummary(data)
        .then((response) => {
          if (!response.success && response.message === "Failed") {
            this.trackAddToCartButtonClick(ErrorMessages.UnableToAddItems);
            this.addNotification(ErrorMessages.UnableToAddItems, "error");
          } else if (!response.success && response.message === "Limit") {
            this.trackAddToCartButtonClick(ErrorMessages.CartLimit);
            this.addNotification(ErrorMessages.CartLimit, "warning");
          } else if (!response.success) {
            this.trackAddToCartButtonClick(response.message);

            this.setState({
              selectedElement: element,
              showConfirmationPopup: true,
              message: response.message,
            });
          } else if (response.success) {
            this.trackAddToCartButtonSuccess(
              element.description,
              element.stockCode
            );
            this.addNotification(DisplayMessages.AddedProductAccessory, "info");
          }
        })
        .catch((error) => {
          this.addNotification(ErrorMessages.UnableToAddToCart, "error");
        });
    }
  }

  onItemQuantityChange(e, element) {
    let stockCode = element.stockCode;
    let qty = e.target.validity.valid ? e.target.value : element.itemQuantity;
    qty = parseInt(qty);

    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );

    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    let sortedItemList = this.state.sortedSearchItemList;
    sortedItemList = sortedItemList.map((item) =>
      item.stockCode === stockCode
        ? { ...item, itemQuantity: qty, shipTime: shipTime }
        : item
    );

    let itemList = this.state.searchItemList;
    itemList = itemList.map((item) =>
      item.stockCode === stockCode
        ? { ...item, itemQuantity: qty, shipTime: shipTime }
        : item
    );
    this.setState({
      sortedSearchItemList: sortedItemList,
      searchItemList: itemList,
    });
  }

  onItemQuantityBlur(e, element) {
    let stockCode = element.stockCode;
    let qty = e.target.value === "" ? 1 : e.target.value;

    let shipTime = Miscellaneous.LeadTimeUnavailable;
    let shipInfo = element.shipInfo.sort(
      (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
    );

    let flag = 0;
    shipInfo.forEach((shipdata) => {
      if (
        parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
        flag === 0
      ) {
        flag = 1;
        shipTime = shipdata.LeadTime;
      }
    });

    let sortedItemList = this.state.sortedSearchItemList;
    sortedItemList = sortedItemList.map((item) =>
      item.stockCode === stockCode
        ? { ...item, itemQuantity: parseInt(qty), shipTime: shipTime }
        : item
    );

    let itemList = this.state.searchItemList;
    itemList = itemList.map((item) =>
      item.stockCode === stockCode
        ? { ...item, itemQuantity: parseInt(qty), shipTime: shipTime }
        : item
    );
    this.setState({
      sortedSearchItemList: sortedItemList,
      searchItemList: itemList,
    });
  }

  sortSettingsSection() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            style={{ fontSize: "13px", display: "flex", alignItems: "center" }}
          >
            Click on{" "}
            <span id="stockCode" className="sort-icon-settings">
              <span
                id="stockCode"
                className="glyphicon glyphicon-triangle-top "
                style={{ color: "#1b2d5b", fontSize: "11px" }}
              />
              <span
                id="stockCode"
                className="glyphicon glyphicon-triangle-bottom"
                style={{ color: "#1b2d5b", fontSize: "11px" }}
              />
            </span>{" "}
            next to a column to sort results.
          </span>
        </div>
        <div>
          <Button
            className="button-blue"
            style={{ fontWeight: "bold" }}
            onClick={this.toggleSortSetting}
          >
            {this.state.showAllSKUs
              ? "Only show SKUs in stock"
              : "Show all SKUs"}
          </Button>
        </div>
      </div>
    );
  }

  toggleSortSetting() {
    let showAllSKUs = !this.state.showAllSKUs;
    this.trackShowOnlyStockSKUButtonClick(showAllSKUs);
    this.setState((prevState) => ({
      sortedSearchItemList: !prevState.showAllSKUs
        ? prevState.searchItemList
        : prevState.searchItemList.filter(
            (item) => item.shipTime !== Miscellaneous.LeadTimeUnavailable
          ),
      showAllSKUs: showAllSKUs,
    }));
  }

  searchResultsText() {
    return (
      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
        Search results for: "
        <span style={{ color: "#074391" }}>{this.state.searchString}</span>"{" "}
      </span>
    );
  }

  CustomerDropdownSection() {
    if (this.state.singleCustomer) {
      return null;
    } else {
      return (
        <div>
          <Row>
            <Col
              md={6}
              sm={6}
              xs={6}
              style={{ padding: "0px", fontSize: "14px" }}
            >
              <Row>
                <span style={{ fontWeight: "bold" }}>Select Customer</span>
              </Row>
              <Creatable
                maxMenuHeight={190}
                value={{
                  value: this.state.selectedCustomer,
                  label:
                    this.state.selectedCustomer === null ||
                    this.state.selectedCustomer === ""
                      ? "Select..."
                      : this.state.selectedCustomer,
                }}
                aria-label="Select Customer"
                styles={customStyles}
                isClearable
                onChange={this.handleCustomerSelect}
                options={this.state.customerList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "lightgray",
                    primary: "lightgray",
                  },
                })}
              />
            </Col>
            <Col md={3} sm={3} xs={3} style={{ fontSize: "14px" }}>
              <Row>
                <span style={{ fontWeight: "bold" }}>Customer Tier</span>
              </Row>
              {this.state.isNewCustomer ? (
                <Select
                  maxMenuHeight={190}
                  menuPlacement="auto"
                  value={{
                    value: this.state.tierSelected,
                    label: this.state.tierSelected,
                  }}
                  aria-label="Customer Tier"
                  styles={customStyles}
                  onChange={this.handleTierSelect}
                  options={this.state.tierList.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "lightgray",
                    },
                  })}
                />
              ) : (
                <FormControl
                  readOnly
                  type="text"
                  value={this.state.customerTier}
                  style={{ height: "20px", minHeight: "34px" }}
                />
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }

  setQuantitySection() {
    return (
      <div>
        <Row>
          <span style={{ float: "right" }}>
            Enter a Qty to check availability for all SKUs
          </span>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControl
            type="number"
            min="1"
            max="10000"
            pattern="[0-9]*"
            style={{ width: "250px" }}
            value={this.state.quantity}
            onBlur={this.onBlurQuantityHandler}
            onChange={this.quantityChangedHandler}
            aria-label="Quantity"
          />
          <Button
            className="button-blue"
            aria-label="Set Quantity"
            onClick={this.searchListQuantityChange}
          >
            <span className="glyphicon glyphicon-ok" />
          </Button>
        </div>
      </div>
    );
  }

  onBlurQuantityHandler(event) {
    let qty = event.target.value === "" ? 1 : event.target.value;
    qty = parseInt(qty);

    let sortedList = this.state.sortedSearchItemList.map((item) => {
      let shipTime = Miscellaneous.LeadTimeUnavailable;
      let shipInfo = item.shipInfo.sort(
        (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
      );

      let flag = 0;
      shipInfo.forEach((shipdata) => {
        if (
          parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
          flag === 0
        ) {
          flag = 1;
          shipTime = shipdata.LeadTime;
        }
      });
      return { ...item, itemQuantity: qty, shipTime: shipTime };
    });

    let list = this.state.searchItemList.map((item) => {
      let shipTime = Miscellaneous.LeadTimeUnavailable;
      let shipInfo = item.shipInfo.sort(
        (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
      );

      let flag = 0;
      shipInfo.forEach((shipdata) => {
        if (
          parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
          flag === 0
        ) {
          flag = 1;
          shipTime = shipdata.LeadTime;
        }
      });
      return { ...item, itemQuantity: qty, shipTime: shipTime };
    });
    this.setState({
      quantity: qty,
      searchItemList: list,
      sortedSearchItemList: sortedList,
    });
  }

  onForceBlurQuantityHandler(qty) {
    qty = parseInt(qty);

    let sortedList = this.state.sortedSearchItemList.map((item) => {
      let shipTime = Miscellaneous.LeadTimeUnavailable;
      let shipInfo = item.shipInfo.sort(
        (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
      );

      let flag = 0;
      shipInfo.forEach((shipdata) => {
        if (
          parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
          flag === 0
        ) {
          flag = 1;
          shipTime = shipdata.LeadTime;
        }
      });
      return { ...item, itemQuantity: qty, shipTime: shipTime };
    });

    let list = this.state.searchItemList.map((item) => {
      let shipTime = Miscellaneous.LeadTimeUnavailable;
      let shipInfo = item.shipInfo.sort(
        (a, b) => parseInt(a.QtyAvailable, 10) - parseInt(b.QtyAvailable, 10)
      );

      let flag = 0;
      shipInfo.forEach((shipdata) => {
        if (
          parseInt(qty, 10) <= parseInt(shipdata.QtyAvailable, 10) &&
          flag === 0
        ) {
          flag = 1;
          shipTime = shipdata.LeadTime;
        }
      });
      return { ...item, itemQuantity: qty, shipTime: shipTime };
    });
    this.setState({
      quantity: qty,
      searchItemList: list,
      sortedSearchItemList: sortedList,
    });
  }

  quantityChangedHandler(event) {
    let qty = event.target.validity.valid
      ? event.target.value
      : this.state.quantity;

    qty = parseInt(qty);
    this.setState({
      quantity: qty,
    });
  }

  searchListQuantityChange() {
    this.setState(
      (prevState) => ({
        searchItemList: prevState.searchItemList.map((item) => ({
          ...item,
          itemQuantity: prevState.quantity,
        })),
        sortedSearchItemList: prevState.sortedSearchItemList.map((item) => ({
          ...item,
          itemQuantity: prevState.quantity,
        })),
      }),
      () => {
        this.trackSetQuantityButtonClick();
        this.onForceBlurQuantityHandler(this.state.quantity);
      }
    );
  }

  handleOnPopupClose() {
    this.setState({
      showSpecLinkPopup: false,
    });
  }

  showSpecLink(element) {
    let specLinkPopupDescription = element.description;
    let specLinkPopupStockCode = element.stockCode;
    let productId = element.productId;
    let specValue = element.specValue;

    this.setState(
      {
        showSpecLinkPopup: true,
        specLinkPopupStockCode: specLinkPopupStockCode,
        specLinkPopupDescription: specLinkPopupDescription,
      },
      () => {
        this.trackSpecsClick(
          specValue,
          specLinkPopupDescription,
          specLinkPopupStockCode,
          productId
        );
        this.getSpecLinks(specLinkPopupDescription, productId);
      }
    );
  }

  getSpecLinks(description, pid) {
    let element = {
      description: description,
      productId: pid.toString(),
    };
    ConfigHandler.getSpecLinks(element)
      .then((response) => {
        let data = response.data.find((item) => item.Specs !== "0");
        if (data !== null && data !== undefined) {
          this.setState({
            engSpecLink: data.EngSpecLink,
            frSpecLink: data.FRSpecLink,
            specValue: data.Specs,
            itemCategory: data.Category,
          });
        } else {
          this.setState({
            specValue: "N/A",
            itemCategory: element.productId !== "NA" ? "base" : "accessories",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addNotification(message, level) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
      });
    }
  }

  // Mixpanel Tracking //

  trackCustomerSelection(customer) {
    mixpanel.track("Customer Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer is displayed",
      Customer: customer,
    });
  }

  trackCustomerTierSelection(tier) {
    mixpanel.track("Customer Tier Selection", {
      Action: "Dropdown Selection",
      Effect: "Selected customer tier is displayed",
      Tier: tier,
    });
  }

  trackSpecsClick(specs, description, stockCode, pid) {
    mixpanel.track("Specs", {
      Action: "Button Click",
      Effect: "Show/Hide Specs Tooltip",
      Specs: specs,
      Description: description,
      "Stock Code": stockCode,
      "Product ID": pid,
    });
  }

  trackAddToCartButtonClick(message) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Attempt to add item(s) to current estimate summary cart",
      Page: "Search Results Page",
      "Added To Cart": false,
      Message: message,
    });
  }

  trackAddToCartButtonSuccess(description, stockCode) {
    mixpanel.track("Add To Cart", {
      Action: "Button Click",
      Effect: "Successfully added to cart",
      Page: "Search Results Page",
      "Added To Cart": true,
      "SKU Description": description,
      "SKU Stock Code": stockCode,
    });
  }

  trackSortSearchResults(id, sortEnabled) {
    mixpanel.track("Sort Search Results", {
      Action: "Button Click",
      Effect: "Sort search results based on selected parameter",
      "Sort Parameter": id,
      "Sort Order": sortEnabled ? "Ascending" : "Descending",
    });
  }

  trackShowOnlyStockSKUButtonClick(showAll) {
    mixpanel.track("Show All SKUs", {
      Action: "Button Click",
      Effect: showAll ? "Show All SKUs" : "Show only SKUs in stock",
      "Show all SKUs": showAll,
    });
  }

  trackSetQuantityButtonClick() {
    mixpanel.track("Set Quantity", {
      Action: "Button Click",
      Effect: "Set specified quantity to all the search results",
      Quantity: this.state.quantity,
    });
  }
}

export default SearchList;
